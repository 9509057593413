'use strict';

export const sortUp = ( arrlist, index, $set ) => {
	let element = JSON.parse(JSON.stringify( arrlist[index] ));
	let prevElement = JSON.parse(JSON.stringify( arrlist[index-1] ));
	$set( arrlist, index, prevElement );
	$set( arrlist, index-1, element );
}

export const sortDown = ( arrlist, index, $set ) => {
	let element = JSON.parse(JSON.stringify( arrlist[index] ));
	let nextElement = JSON.parse(JSON.stringify( arrlist[index+1] ));
	$set( arrlist, index, nextElement );
	$set( arrlist, index+1, element );
}