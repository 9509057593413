export default {
    cardList: [],
    cardsMap: {},
    stateCardData: {},
    cardData: {
        title: '',
        content_0: '',
        content_1: '',
    },
    stateDefinitionData: {},
    definitionData: {
        title: '',
        content_0: '',
        content_1: '',
    },
}