export default ( mainData, comparisonData ) => {
    let counter = 0;
    let changedKeys = [];
    if ( Object.keys(comparisonData).length ) {
        for ( let prop in mainData ) {
            if ( Object.hasOwnProperty.call( mainData, prop ) ) {
                if ( prop === 'image' && comparisonData[prop] !== mainData[prop] ) {
                    counter += 1;
                    changedKeys.push(prop);
                } else if (
                    ['string','number','boolean'].includes(typeof mainData[prop]) &&
                    comparisonData[prop] !== mainData[prop]
                ) {
                    counter += 1;
                    changedKeys.push(prop);
                }
                else if ( Array.isArray( mainData[prop] ) ) {
                    const arr1 = mainData[prop];
                    const arr2 = comparisonData[prop];
                    const equal = arr1.length == arr2.length && arr1.every( v => arr2.includes(v) )
                    if ( !equal ) {
                        counter += 1;
                        changedKeys.push(prop);
                    }
                }
                else if ( typeof mainData[prop] === 'object' && typeof comparisonData[prop] === 'object' && comparisonData[prop] && mainData[prop] ) {
                    for ( let key in mainData[prop] ) {
                        if ( Object.hasOwnProperty.call( mainData[prop], key ) ) {
                            if ( key === 'image' && comparisonData[prop][key] !== mainData[prop][key] ) {
                                counter += 1;
                                changedKeys.push(prop);
                            } else if (
                                ['string','number','boolean'].includes(typeof mainData[prop][key]) &&
                                comparisonData[prop][key] !== mainData[prop][key]
                            ) {
                                counter += 1;
                                changedKeys.push(prop);
                            }
                        }
                    }
                }
                else if (typeof mainData[prop] !== typeof comparisonData[prop]) {
                    counter += 1;
                    changedKeys.push(prop);
                }
            }
        }
    }
    let output = {
        saveBtnDisabled: true,
        changedKeys
    }
    if ( counter ) output.saveBtnDisabled = false;
    return output;
}