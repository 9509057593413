

export default {
    menuOpened: false,
    pageHeaderData: {
        history: {}
    },
    tablePagination: {},
    popupSelectValue: {},
    clipboard: null,
    countries: {},
    dashboardMenu: [],
    admin_roles: false,
    breadcrumbs: '',
    user_id: '',
    contracts: [],
    features: {
        payable: false,
        trainer: false,
        messages: false,
        contracts: false,
        websystem_menus: false,
        websystem_pages: false,
        aiTextGenerate: false
    },
    allowedMediaTypes: {},
    basicDataLoaded: false,
    monetizationBundle: '',
    stripe: {}
}