export default {
    monetizationBundleList: [],
    monetizationBundleGroup: [],
    singleData: {},
    localMBData: {
        title: '',
        description: '',
        groups: [],
        content: {
            image: '',
            text: '',
        },
        banner: {
            image: '',
            text: '',
        },
        overview: {
            image: '',
            text: '',
        },
        checkout: {
            image: '',
            text: '',
        },
        status: 0,
        requestDone: false
    },
    states: [
        { value: 1, label: 'Nordrhein-Westfalen' },
        { value: 2, label: 'Baden Württemberg (Coming Soon)', disabled: true },
        { value: 3, label: 'Bayern (Coming Soon)', disabled: true },
        { value: 4, label: 'Berlin (Coming Soon)', disabled: true },
        { value: 5, label: 'Brandenburg (Coming Soon)', disabled: true },
        { value: 6, label: 'Bremen (Coming Soon)', disabled: true },
        { value: 7, label: 'Hamburg (Coming Soon)', disabled: true },
        { value: 8, label: 'Hessen (Coming Soon)', disabled: true },
        { value: 9, label: 'Mecklenburg-Vorpommern (Coming Soon)', disabled: true },
        { value: 10, label: 'Niedersachsen (Coming Soon)', disabled: true },
        { value: 11, label: 'Rheinland-Pfalz (Coming Soon)', disabled: true },
        { value: 12, label: 'Saarland (Coming Soon)', disabled: true },
        { value: 13, label: 'Sachsen (Coming Soon)', disabled: true },
        { value: 14, label: 'Sachsen-Anhalt (Coming Soon)', disabled: true },
        { value: 15, label: 'Schleswig-Holstein (Coming Soon)', disabled: true },
        { value: 16, label: 'Thüringen (Coming Soon)', disabled: true },
    ],
}