export default {

	addNotification:(state, payload={})=>{
		if(payload.variant && payload.msg ) {

			let notification = {
				msg: []
			}
			for ( let prop in payload ) {
				if ( prop !== 'msg' && Object.hasOwnProperty.call( payload, prop ) ) {
					notification[prop] = payload[prop];
				}
			}

			if ( typeof payload.msg === "string" ) {
				notification.msg.push( payload.msg );
			}
			else if ( Array.isArray(payload.msg) ) {
				notification.msg = payload.msg;
			}
			else if ( typeof payload.msg === "object" && !Array.isArray(payload.msg) && payload.msg !== null ) {
				if ( Object.keys( payload.msg ).length !== 0 ) {
					Object.keys( payload.msg ).forEach( key => {
						notification.msg.push( payload.msg[key] );
					} )
				} else {
					notification.msg.push( Object.values( payload.msg ) );
				}
			}

			notification.msg.forEach( msg => {
				setTimeout( () => {
					if ( msg.includes( '#' ) ) {
						notification.variant = 'warning';
						msg = msg.split( '#' );
						if ( payload.labels && payload.labels[msg[0]] ) {
							notification.msg = `${payload.labels[msg[0]]}: ${msg[1]}`;
							state.commit( 'runNotification', notification );
						} else {
							notification.msg = `${msg[0]} ${msg[1]}`;
							state.commit( 'runNotification', notification );
						}
					} else {
						if ( payload.labels && payload.labels[msg] ) {
							notification.msg = payload.labels[msg];
							state.commit( 'runNotification', notification );
						} else {
							notification.msg = msg;
							state.commit( 'runNotification', notification );
						}
					}
				}, 100 );
			} );
		}
	},

}