<template>
  <svg
    v-if="loading"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :style="{ margin: 'auto', background: bgColor, display: 'block' }"
    :width="width"
    :height="height"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#a792e0"
      stroke-width="3"
      r="35"
      stroke-dasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
</template>

<script>
const colors = require("/colors.config.json");
export default {
  name: "SvgLoader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: `${colors.white}`,
    },
    width: {
      type: String,
      default: "200px",
    },
    height: {
      type: String,
      default: "200px",
    },
  },
};
</script>

<style lang="scss" scoped></style>
