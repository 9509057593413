export default [
	{
		path: '/dashboard/affiliate-links-overview',
		name: 'affiliate-links-overview',
		meta: {
			relation: 'affiliate-link',
		},
		components: {
			dashboard: () => import('@/views/Dashboard/Affiliate/Overview.vue')
		},
		children: [
			{
				path: '/dashboard/affiliate-links-overview/create',
				name: 'create-affiliate-link',
				meta: {
					relation: 'affiliate-link',
				},
				components: {
					create_affiliate_link: () => import('@/views/Dashboard/Common/CreateEntity.vue')
				},
			},
		]
	},
	{
		path: '/dashboard/affiliate/:id',
		name: 'affiliate-link',
		meta: {
			relation: 'affiliate-link',
		},
		components: {
			dashboard: () => import('@/views/Dashboard/Affiliate/Analytics.vue')
		},
	},
]