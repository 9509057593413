export default [
	{
		path: '/dashboard/card/:id',
		name: 'edit-card',
		// meta: {
		// 	relation: 'card',
		// },
		components: {
			dashboard: () => import('@/views/Dashboard/Card/Main.vue')
		},
		children: []
	},
	{
		path: '/dashboard/definition/:id',
		name: 'edit-definition',
		// meta: {
		// 	relation: 'definition',
		// },
		components: {
			dashboard: () => import('@/views/Dashboard/Definition/Main.vue')
		},
		children: []
	},
]