export default {
    updateMessageList: (state, payload) => state.messageList = payload,
    
    updateSingleMessage: (state, payload) => {
        state.singleData = payload;
        state.stateSingleData = payload;
    },

    setPreUpdateData: (state, payload) => {
        state.stateSingleData = payload;
    },

    updateCreateMessageData: (state, payload) => state.createMessageData = payload,

    setMessagePublishData: (state, payload) => {
        state.singleData.published = payload;
        state.stateSingleData.published = payload;

        if (typeof payload == 'object' && Object.keys(payload).length) {
            state.singleData.publish = true;
            state.stateSingleData.publish = true;
        } else {
            state.singleData.publish = false;
            state.stateSingleData.publish = false;
        }
    }
};