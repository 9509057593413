export default {
    updateMonetizationBundleList: ( state, data ) => state.monetizationBundleList = data,

    updateSingleMBData: ( state, payload ) => state.singleData = payload,

    updateLocalMBData: ( state, payload ) => {
        let copy = JSON.parse(JSON.stringify(payload))
        for ( let prop in payload ) {
            if ( ['title', 'description', 'groups', 'content', 'banner', 'overview', 'checkout', 'status', 'requestDone'].includes(prop) ) {
                state.localMBData[prop] = copy[prop];
            }
        }
    },

    updateLocalMBDataString: ( state, payload ) => state.localMBData[payload.key] = payload.value,

    updateLocalMBDataObject: ( state, payload ) => state.localMBData[payload.key_0][payload.key_1] = payload.value,
}