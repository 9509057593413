import axios from 'axios';

export default {
    create: (state, data) => {
        return new Promise((done) => {
            axios.post("/card/create", data)
                .then((res) => done(res.data))
                .catch((err) => done(err))
        })
    },
    getList: (state, data) => {
        return new Promise((done) => {
            axios.get(`card/list/${data.page}`, data)
                .then(res => {
                    state.commit('updateCardList', res.data.data.data);
                    done(res.data)
                })
                .catch((err) => done(err))
        })
    },
    getSingle: (state, data) => {
        return new Promise(done => {
            axios.get(`/card/single/${data.id}`)
                .then(res => {
                    state.commit('updateSingleCard', res.data.data);
                    done(res.data);
                })
                .catch(err => done(err))
        })
    },
    getPublicSingle: (state, data) => {
        return new Promise(done => {
            axios.get(`/account/definitions/${data.id}`, {
                    baseURL: '/api/r'
                })
                .then(res => {
                    state.commit('updateSingleCard', res.data.data);
                    done(res.data);
                })
                .catch(err => done(err))
        })
    },
    update: (state, data) => {
        return new Promise(done => {
            axios.post(`/card/update`, data)
                .then(res => done(res.data))
                .catch(err => done(err))
        })
    },
}