import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

import TextEditor from '@/components/Input/TextEditor'
import PopupSelectInput from '@/components/Input/PopupSelectInput'
import DropDown from "@/components/Helpers/DropDown";
Vue.component('TextEditor', TextEditor)
Vue.component('PopupSelectInput', PopupSelectInput)
Vue.component('DropDown', DropDown)

Vue.directive('click-outside', {
  bind(el, binding) {
    el.addEventListener('click', e => e.stopPropagation());
    document.body.addEventListener('click', binding.value);
  },
  unbind(el, binding) {
    document.body.removeEventListener('click', binding.value);
  }
});

import VueFormulate from '@braid/vue-formulate';
Vue.use(VueFormulate, {
  library: {
    'text-editor': {
      classification: 'textarea',
      component: 'TextEditor',
      slotProps: {
        component: ['extraConfigs']
      }
    },
    dropDown: {
      classification: 'select',
      component: 'DropDown',
    },
    'popup-select': {
      classification: 'text',
      component: 'PopupSelectInput'
    },
  }
});

import VueDragscroll from 'vue-dragscroll';
Vue.use(VueDragscroll);

import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.min.js';

import axios from "axios";
axios.defaults.baseURL = "/api/ra/";
axios.defaults.withCredentials = true;

import axiosservice from "@/services/axios-set-token"
axiosservice(axios, store);

Vue.config.productionTip = false

require('@/services/prototypes');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
