import axios from "axios";

export default {
  create: (state, data) => {
    return new Promise((done) => {
      axios
        .post("/message/create", data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  archive: (state, data) => {
    return new Promise((done) => {
      axios
        .post("/message/archive", data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  action: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/message/action`, { id: data.id })
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  getList: (state, data) => {
    return new Promise((done) => {
      let urlParameter = "";
      if (data.search || data.titleId || data.excludeId || data.parent) {
        let urlParameterArray = [];
        if (data.search) urlParameterArray.push(`search=${data.search}`);
        if (data.excludeId)
          urlParameterArray.push(`excludeId=${data.excludeId}`);
        if (data.parent) urlParameterArray.push(`parent=${data.parent}`);
        urlParameter = `?${urlParameterArray.join("&")}`;
      }
      axios
        .get(`/message/list/${data.page}${urlParameter}`)
        .then((res) => {
          state.commit("updateMessageList", res.data.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getSingle: (state, data) => {
    return new Promise((done) => {
      axios
        .get(`/message/single/${data.id}`)
        .then((res) => {
          state.commit("updateSingleMessage", res.data.data);
          state.commit("updateCreateMessageData", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  update: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/message/update`, data)
        .then((res) => {
          if (res.data.success)
            state.commit("updateSingleMessage", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  delete: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/message/delete`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  addNote: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/message/add-note`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  deleteNote: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/message/delete-note`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  updateNote: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/message/update-note`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  togglePublish: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/message/toggle-publish`, data)
        .then((res) => {
          if (res.data.success)
            state.commit("setMessagePublishData", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
};
