<template>
  <div class="editor">
    <div v-if="editor" :editor="editor" class="editor__menubar menubar">
      <input type="color" @input="editor.chain().focus().setColor($event.target.value).run()"
        :value="getColor(editor.getAttributes('textStyle').color)" :class="colorPickerClasses" />

      <input type="color" @input="editor.chain().focus().setBgColor($event.target.value).run()"
        :value="getColor(editor.getAttributes('textStyle').bgColor)" :class="bgColorPickerClasses" />

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
        <icon name="H1" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        <icon name="H2" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
        <icon name="H3" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()">
        <icon name="bold" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()">
        <icon name="Italic" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('strike') }"
        @click="editor.chain().focus().toggleStrike().run()">
        <icon name="Strike" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('underline') }"
        @click="editor.chain().focus().toggleUnderline().run()">
        <icon name="Underline" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('code') }"
        @click="editor.chain().focus().toggleCode().run()">
        <icon name="Code" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('paragraph') }"
        @click="editor.chain().focus().setParagraph().run()">
        <icon name="Paragraph" />
      </button>

      <button @click="setLink" :class="{ 'is-active': editor.isActive('link'), menubar__button: true }">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="20px" height="20px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
          <path fill="#838383" d="M459.654,233.373l-90.531,90.5c-49.969,50-131.031,50-181,0c-7.875-7.844-14.031-16.688-19.438-25.813
                    l42.063-42.063c2-2.016,4.469-3.172,6.828-4.531c2.906,9.938,7.984,19.344,15.797,27.156c24.953,24.969,65.563,24.938,90.5,0
                    l90.5-90.5c24.969-24.969,24.969-65.563,0-90.516c-24.938-24.953-65.531-24.953-90.5,0l-32.188,32.219
                    c-26.109-10.172-54.25-12.906-81.641-8.891l68.578-68.578c50-49.984,131.031-49.984,181.031,0
                    C509.623,102.342,509.623,183.389,459.654,233.373z M220.326,382.186l-32.203,32.219c-24.953,24.938-65.563,24.938-90.516,0
                    c-24.953-24.969-24.953-65.563,0-90.531l90.516-90.5c24.969-24.969,65.547-24.969,90.5,0c7.797,7.797,12.875,17.203,15.813,27.125
                    c2.375-1.375,4.813-2.5,6.813-4.5l42.063-42.047c-5.375-9.156-11.563-17.969-19.438-25.828c-49.969-49.984-131.031-49.984-181.016,0
                    l-90.5,90.5c-49.984,50-49.984,131.031,0,181.031c49.984,49.969,131.031,49.969,181.016,0l68.594-68.594
                    C274.561,395.092,246.42,392.342,220.326,382.186z" />
        </svg>
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('bullet_list') }"
        @click="editor.chain().focus().toggleBulletList().run()">
        <icon name="Bullets" />
      </button>

      <button type="button" class="menubar__button" :class="{ 'is-active': editor.isActive('ordered_list') }"
        @click="editor.chain().focus().toggleOrderedList().run()">
        <icon name="Numbering" />
      </button>

      <button type="button" class="menubar__button" @click="showImagePrompt()">
        <icon name="Image" />
      </button>

      <button type="button" class="menubar__button" @click="editor.chain().focus().setHorizontalRule().run()">
        <icon name="Minimize" />
      </button>

      <button type="button" class="menubar__button" @click="editor.chain().focus().undo().run()">
        <icon name="Undo" />
      </button>

      <button type="button" class="menubar__button" @click="editor.chain().focus().redo().run()">
        <icon name="Redo" />
      </button>

      <button v-if="aiEnabled" type="button" class="menubar__button generate_button" @click="showAiPopup = true">
        {{ labels.generate }}
      </button>

      <Popup v-if="showAiPopup && aiEnabled" class="ai_popup generate-modal" ref="aiPopup" v-bind:modal-id="`${aiPopupId}-ai-modal`"
        v-bind:onHide="closePopup" v-bind:scrollable="false" v-bind:toggleStatus="showAiPopup" :xCloseBtn="false"
        v-bind:closePopup="closePopup" :onClose="closePopup" style="padding-right: 0 !important;">
        <template v-slot:header>
          <div class="modal-title">{{ labels.generate_content }}</div>
        </template>

        <template v-slot:body>
          <SvgLoader class="loading-slider" :loading="loadingCircle" />

          <div v-if="!loadingCircle" class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content main-content">
              <div class="modal-body">
                <div class="modal-body-inner">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="select-items">
                        <div class="select-items-inner">
                          <div class="select-items-wrapper">
                            <div v-for="(select, key) in selectedItems" :key="key" class="selected-item">
                              <FormulateInput type="textarea" v-model="select.value" />
                              <div class="selected-item-buttons">

                                <button class="selected-item-buttons-move-down" @click="selectedItemMoveDown(key)"
                                  v-if="selectedItems.length > 1 && key < selectedItems.length - 1">

                                  <svg data-v-ecd58e88="" data-v-72ee027d="" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="chevron-down" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    class="svg-inline--fa fa-chevron-down fa-w-14 chevron-down">
                                    <path data-v-ecd58e88="" data-v-72ee027d="" fill="currentColor"
                                      d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                                      class=""></path>
                                  </svg>

                                </button>

                                <button class="selected-item-buttons-move-up " @click="selectedItemMoveUp(key)"
                                  v-if="selectedItems.length > 1 && key > 0">

                                  <svg data-v-ecd58e88="" data-v-72ee027d="" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="chevron-up" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512" class="svg-inline--fa fa-chevron-up fa-w-14 chevron-up">
                                    <path data-v-ecd58e88="" data-v-72ee027d="" fill="currentColor"
                                      d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
                                      class=""></path>
                                  </svg>

                                </button>

                                <button v-if="selectedItems.length > 1" class="selected-item-buttons-delete"
                                  @click="deleteSelectedItem(key)">{{ labels.delete
                                  }}</button>

                              </div>
                            </div>
                          </div>
                          <Button variant="btn-purple add-select-button" :label="labels.add_content_bullet_point"
                            @click="addSelectedItem()" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="right-inner">
                        <FormulateInput class="prompt-input" type="textarea" v-model="prompt" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="!loadingCircle" v-slot:footer>
          <div  class="generate-popup__footer">

            <div class="generate-popup__footer-tokens">
              {{ tokensCount }} {{ labels.tokens }}
            </div>
            <div class="generate-popup__footer-buttons">
              <Button variant="btn-outline-purple" :label="labels.close" @click="closePopup"/>
              <Button variant="btn-purple" :label="labels.generate" @click="generateContent" />
            </div>
          </div>

        </template>
      </Popup>


    </div>
    <div class="content-wrapper">
      <editor-content class="editor__content text" :editor="editor" />
      <!-- <small class="string-count">{{charsCount()}}/{{wordsCount()}} </small> -->
    </div>

  </div>
</template>

<script>
import rgbHex from "rgb-hex";
import Icon from "./TextEditor/RichTextIcon";

import { Editor, EditorContent } from "@tiptap/vue-2";
import { Extension } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Button from "@/components/Buttons/Button";
import Popup from "@/components/Popups/Popup";
import SvgLoader from "@/components/Preloaders/SvgLoader";

import { mapGetters, mapState, mapActions } from "vuex";

const colors = require("/colors.config.json");
// Create background color extension for tiptap
const BgColor = Extension.create({
  name: "bgColor",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          bgColor: {
            default: null,
            parseHTML: (element) =>
              element.style["background-color"].replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.bgColor) {
                return {};
              }

              return {
                style: `background-color: ${attributes.bgColor}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setBgColor:
        (color) =>
          ({ chain }) => {
            return chain().setMark("textStyle", { bgColor: color }).run();
          },
      unsetBgColor:
        () =>
          ({ chain }) => {
            return chain()
              .setMark("textStyle", { bgColor: null })
              .removeEmptyTextStyle()
              .run();
          },
    };
  },
});
  
export default  {
  name: "TextEditor",
  components: {
    EditorContent,
    Icon,
    Popup,
    Button,
    SvgLoader
  },
  data() {
    return {
      insertMedia: {},
      showPopup: false,
      editor: null,

      selectedItems: [{ value: '' }],
      prompt: '',
      showAiPopup: false,
      loadingCircle: false,
      tokensCount: 0,
      originalContent: ''
    };
  },
  props: {
    model: {},
    context: {
      type: Object,
      required: true,
    },
    extraConfigs: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    ...mapActions('content_settings', {
      generateText: "generateText",
    }),
    ...mapActions("helper", {
      getBasic: "getBasic",
    }),
    addSelectedItem() {
      this.selectedItems.push({ value: '' })
    },
    closePopup() {
      if (this.showAiPopup) this.showAiPopup = false
      if(!this.$route.path.includes('create')){
        let element = document.querySelector(".modal-backdrop");
        if (element) {
          element.remove();
        }
      }
    },
    deleteSelectedItem(key) {
      this.selectedItems.splice(key, 1);
    },
    selectedItemMoveUp(key) {
      let temp = this.selectedItems[key]
      this.selectedItems[key] = this.selectedItems[key - 1]
      this.selectedItems[key - 1] = temp

      let arr = this.selectedItems
      this.selectedItems = []
      this.selectedItems = arr
    },
    selectedItemMoveDown(key) {
      let temp = this.selectedItems[key]
      this.selectedItems[key] = this.selectedItems[key + 1]
      this.selectedItems[key + 1] = temp

      let arr = this.selectedItems
      this.selectedItems = []
      this.selectedItems = arr
    },
    async generateContent() {

      this.loadingCircle = true

      let arr = []

      Object.keys(this.selectedItems).forEach(e => {
        if (this.selectedItems[e].value.length)
          arr.push(this.selectedItems[e].value)
      })

      if (!arr.length || !this.prompt.length) return

      let requestAi = `${arr.toString()}. ${this.prompt}.`


      await this.generateText({ prompt: requestAi }).then(res => {
        this.emitEditText(res.response)

        this.editor.commandManager.commands.setContent(`<p><span style="color: #000">${res.response}</span></p>`);
        this.loadingCircle = false
        this.closePopup()

      }).catch(error => {
        console.log(error)
      })

    },
    countTokens() {

      let arr = []

      Object.keys(this.selectedItems).forEach(e => {
        if (this.selectedItems[e].value.length)
          arr.push(this.selectedItems[e].value)
      })

      let requestAi = `${arr.toString()}. ${this.prompt}.`


      // Split the prompt into tokens.
      const tokens = requestAi.split(" ");

      // Count the number of tokens.
      let tokenCount = 0;
      for (const token of tokens) {
        if (token !== "") {
          tokenCount++;
        }
      }

      this.tokensCount = tokenCount

      return tokenCount;
    },
    onHideMediaLibrary(mediaList = []) {
      mediaList.forEach((item) => {
        this.insertMedia({ src: item.media });
      });
      this.showPopup = false;
    },
    showImagePrompt() {
      const src = prompt("Enter the url of your image here");
      if (src !== null) {
        this.editor.chain().focus().setImage({ src: src }).run();
      }
    },
    emitEditText(text) {
      this.context.model = text;
      this.originalContent = text;
      this.$emit("changed", text);
      this.$emit("change", text);
      this.$emit("input", text);
    },
    charsCount() {
      let cont = this.context.model;

      if (cont) {
        cont = cont.trim();
        cont = cont.replace(/<[^>]*>/g, " ");
        let newString = cont.replace(/\s+/g, "");

        return newString.length;
      }
    },
    wordsCount() {
      let cont = this.context.model;

      if (cont) {
        cont = cont.replace(/<[^>]*>/g, " ");
        cont = cont.replace(/\s+/g, " ");
        cont = cont.trim();

        if (cont.length === 0) {
          return 0;
        }

        return cont.split(" ").length;
      }
    },
    getColor(color) {
      if (!color || !color.length) {
        return `${colors.black}`;
      } else {
        if (this.isRgb(color)) color = `#${rgbHex(color)}`;

        return color;
      }
    },
    isRgb(color) {
      if (color.includes("rgb(")) return true;

      return false;
    },
    setEditorTextColor(color) {
      this.editor.commandManager.commands.setColor(color);
    },
    setLink() {
      if (this.editor.isActive("link")) {
        this.editor.chain().focus().unsetLink().run();
      } else {
        const previousUrl = this.editor.getAttributes("link").href;
        const url = window.prompt("URL", previousUrl);

        // cancelled
        if (url === null) {
          return;
        }

        // empty
        if (url === "") {
          this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

          return;
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: url })
          .run();
      }
    },
  },
  mounted() {
    this.originalContent = this.context.model;

    this.editor = new Editor({
      extensions: [
        StarterKit,
        TaskItem,
        TaskList,
        Link,
        Image,
        Underline,
        TextStyle,
        Color,
        BgColor,
      ],
      content: this.context.model,
      onUpdate: (editorObj) => {
        // if (this.extraConfigs?.forceTextColor) {
        //   this.setEditorTextColor(this.extraConfigs.forceTextColor);
        // }

        this.emitEditText(editorObj.editor.getHTML());
      },

      // onSelectionUpdate here used to change color of text when you select it with a mouse. It is super expensive for resources and creates laggs + maximum call stack exceeded errors in current implementation

      // onSelectionUpdate: () => {
      // 	if (this.extraConfigs?.forceTextColor) {
      // 		this.setEditorTextColor(this.extraConfigs.forceTextColor);
      // 	}
      // }

    });

    if (this.extraConfigs?.forceTextColor)
      this.setEditorTextColor(this.extraConfigs.forceTextColor);

    const watcher = this.$watch("contextValue", () => {
      if (this.contextValue != this.contextModel) {
        this.editor.commands.setContent(this.context.value);
        watcher();
      }
    });

    this.getBasic().then(res=>{
      this.prompt = this.current_language ? res.aiPrompt[this.current_language] : res.aiPrompt.en
    })

  },
  beforeUpdate() {
    if (this.extraConfigs?.forceTextColor) {
      this.setEditorTextColor(this.extraConfigs.forceTextColor);
    }
    this.countTokens()
  },
  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  },
  watch: {
    prompt() {
      this.countTokens()
    },
    selectedItems: {
      deep: true,
      handler() {
        this.countTokens()
      },
    },
    context(newVal) {
      if (newVal.model != this.originalContent) {
        this.originalContent = newVal.model;
        this.editor.commands.setContent(this.context.value);
      }
    }
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState('helper', ['current_language', 'features']),
    contextModel() {
      return this.context.model;
    },
    contextValue() {
      return this.context.value;
    },
    colorPickerClasses() {
      let result = {};

      if (this.extraConfigs?.hideColorPicker) result["d-none"] = true;

      return result;
    },
    bgColorPickerClasses() {
      let result = {};

      if (this.extraConfigs?.hideBgColorPicker) result["d-none"] = true;

      return result;
    },
    aiPopupId() {
      if (this.extraConfigs?.aiPopupId) return this.extraConfigs.aiPopupId;

      return Date.now().toString();
    },
    aiEnabled() {
      if (this.features?.aiTextGenerate) return true;

      return false;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

.editor {
  border: 1px solid $light_gray;
  // border-radius: 10px;
  word-break: break-all;

  .menubar {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    color: $gray_text_2;
    border-bottom: 1px solid $light_gray;
    padding: 5px 10px;
    background: #f6f7f7;

    &__button {
      display: inline-block;
      font-weight: 700;
      background: transparent;
      border: 0;
      color: #838383;
      // padding: .2rem .5rem;
      cursor: pointer;
      border: 1px solid transparent;
      margin: 2px;
      border-radius: 2px;

      &:focus,
      &:focus-visible {
        outline: 0;
      }

      &.is-active {
        background: #f0f0f1;
        border-color: #50575e;
        box-shadow: inset 0 2px 5px -3px rgba(0, 0, 0, 0.3);
      }

      svg {
        height: 12px;
        width: 14px;
      }
    }

    .minimize {
      align-self: end;
    }
  }

  .content-wrapper {
    position: relative;
    height: 100%;

    // .string-count {
    // 	position: absolute;
    // 	top: 7px;
    // 	right: 14px;
    // 	color: $light_text;
    // 	font-size: 0.75rem;
    // }
  }

  &__content {
    background-color: $white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      /* Прозорий колір бара */
    }

    ::v-deep .ProseMirror {
      min-height: 100px;

      &:focus-visible {
        outline: none;
      }
    }

    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $black;
      color: $white;
      font-size: 0.8rem;
      overflow-x: auto;
    }

    blockquote {
      border-left: 3px solid rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.8);
      padding-left: 0.8rem;
      font-style: italic;
    }
  }

  ::v-deep .ProseMirror {
    >*+* {
      margin-top: 0.75em;
    }

    a {
      span {
        color: #007bff !important;
      }
    }

    img {
      margin: 0.75em;
      width: auto;
      max-height: 300px;

      &.ProseMirror-selectednode {
        outline: 3px solid #68cef8;
      }
    }
  }

  ::v-deep {
    .generate-modal {
      background-color: rgba(0, 0, 0, 0.7);
      display: flex !important;
      align-items: center;
      .modal-content:not(.main-content){
        @media (min-width: 991px) {
          min-width: 798px;
        }
      }
      .modal-content{
        border: none;
      }
      .modal-header {
        padding: 15px;
        background-color: #f2f2f2;

        .modal-title {
          font-family: "Oswald", sans-serif;
          font-weight: 600;
          font-size: 20px;
        }
      }

      .modal-body {
        max-height: 400px;
        padding: 0 15px;
        overflow: hidden;

        @media (max-width: 991px) {
          max-height: fit-content;
          overflow: visible;
        }

        .row {
          row-gap: 25px;
        }

        .modal-body-inner {

          .select-items {
            height: 100%;

            .selected-item {
              margin-bottom: 15px;
              border-bottom: 1px solid #cecece;
              padding: 20px;

              .formulate-input {
                margin-bottom: 0;
                width: 100%;
                margin-bottom: 10px;

                .formulate-input-element--textarea {
                  textarea {
                    min-height: 100px;
                    resize: none;
                  }

                  max-width: 100%;
                }
              }
            }

            .select-items-inner {
              border: 1px solid #cecece;
              border-radius: 0.3em;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 15px;
              height: 100%;
              max-height: 266px;
              overflow-y: auto;

              .add-select-button {
                max-width: 90%;
              }

              .select-items-wrapper {
                width: 100%;

                .selected-item-buttons {
                  display: flex;
                  justify-content: flex-end;
                  gap: 10px;
                  flex-wrap: wrap;
                  align-items: center;

                  &-move-down,
                  &-move-up {
                    border-radius: 50%;
                    background: transparent;
                    width: 20px;
                    height: 20px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #cecece;

                    svg {
                      width: 10px;
                      height: 10px;

                      path {
                        fill: $gray_btn;
                      }
                    }

                    &:focus-visible,
                    &:focus {
                      border: 1px solid $gray_btn;
                      outline: none;
                    }
                  }

                  &-move-up {}

                  &-delete {
                    border: 1px solid $danger;
                    border-radius: 5px;
                    color: $danger;
                    font-weight: 500;
                    background-color: transparent;

                    &:focus-visible,
                    &:focus {
                      border: 1px solid $gray_btn;
                      outline: none;
                    }
                  }
                }
              }
            }
          }

          .right-inner {
            .formulate-input-element--textarea {
              max-width: 100%;

              textarea {
                min-height: 200px;
                padding: 0;
                border: none;
                font-size: 16px;
                color: $light_text;
                resize: none;
                @media (max-height: 700px) {
                  min-height: 90px;
                }

                &:focus {
                  color: red;
                  font-size: 0.9rem;
                  border: 1px solid $lightgray;
                  padding: 0.75em;
                  color: $dark;
                }
              }
            }
          }

          .max-words-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            gap: 15px;
          }
        }
      }

      .generate-popup__footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        &-buttons {
          display: flex;
          gap: 15px;
        }
      }
    }
  }

}
</style>
