<template>
  <div
    :class="['modal fade', customClass]"
    tabindex="-1"
    role="dialog"
    :style="styles"
    :id="modalId"
    :aria-labelledby="`${modalId}Label`"
    aria-hidden="true"
    @mousedown="onHide"
  >
    <div
      :class="[
        'modal-dialog',
        `modal-${size}`,
        { 'modal-dialog-centered': vCenter },
      ]"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header"></slot>
          <button
            v-if="xCloseBtn"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" @click="onClose">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <slot name="body"></slot>
        </div>

        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    modalId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "lg",
    },
    onClose: {
      type: Function,
      default: () => 1,
    },
    borderRadius: {
      type: String,
      default: "10px",
    },
    vCenter: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    toggleStatus: {
      type: Boolean,
      default: false,
    },
    xCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      styles: {
        "--border-radius": this.borderRadius,
      },
    };
  },
  methods: {
    onHide(ev) {
      if (
        ev.target.id === this.modalId &&
        ev.target.className.includes("modal")
      ) {
        let element = document.querySelector(".modal-backdrop");
        if (element) {
          element.remove();
          document.querySelector("body").classList.remove("modal-open");
        }
        this.onClose();
      }
    },
  },
  beforeMount() {
    let element = document.querySelector(".modal-backdrop");
    if (element) {
      element.remove();
      document.querySelector("body").classList.remove("modal-open");
    }
  },
  mounted() {
    if (this.toggleStatus) this.$(`#${this.modalId}`).modal("show");
  },
  destroyed() {
    if (!this.toggleStatus) {
      this.$(`#${this.modalId}`).modal("hide");
      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    }
  },
  watch: {
    toggleStatus(value) {
      if (value) this.$(`#${this.modalId}`).modal("show");
      else this.$(`#${this.modalId}`).modal("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.modal {
  &.gray-header {
    .modal-header {
      font-family: "Oswald", sans-serif;
      background-color: $background_3;
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: unset;
    }

    // .modal-body {
    //     padding-bottom: 0;
    // }

    // .modal-footer {
    //     border-top: unset;
    // }
  }

  &-content {
    border-radius: var(--border-radius);
    max-height: calc(100vh - 1rem);

    @media (min-width: 576px) {
      max-height: calc(100vh - 3.5rem);
    }
  }

  &-header {
    font-size: 1.125rem;
  }

  &-body {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: $scrollbar_width;
    }

    &::-webkit-scrollbar-track {
      background: $light_gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $navy;
    }
  }

  // &-footer {}

  &-header,
  &-body,
  &-footer {
    padding: 15px;

    @media (min-width: 1200px) {
      padding: 25px;
    }
  }
}
</style>
