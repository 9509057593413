/**
 * /account/profile
 */

 export default [
	{
		path: 'profile',
		name: 'account-profile',
		meta: {
			relation: 'profile',
		},
		components: {
			client: () => import('@/views/Account/Profile.vue')
		},
	},
]