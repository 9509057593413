'use strict';

export default {
    getMenu: ( state, menu ) => {
        if( menu ){
            state.menu = menu.data;
            state.pagination = menu.pages;
            state.permissions = menu.permissions;
        }
    },
    getMenuForEditor: ( state, menu ) => {
        if( menu ){
            state.singleEntity = menu;
        }
    },
    // this function sets object property value in object stored in state
    // has an exlusion in case slotId is provided, we need to append new value in body for provided slotId(so we do not refresh entire body prop)
    setEntityPropValue: ( state, payload ) => {
        state.singleEntity[ payload.key ].value = payload.value;
    },
    toggleMenuCollapsible: ( state, payload ) => {
        let deleted = false;
        for( let i = 0; i < state.openMenuItems.length; i ++ ){
            if( state.openMenuItems[i] === payload ){
                // delete
                state.openMenuItems.splice( i, 1 );
                deleted = true;
            }
        }
        if( !deleted ){
            state.openMenuItems.push( payload );
        }
    }
}