import Vue from 'vue'
import Vuex from 'vuex'

import notification from "./notification";
import helper from "./helper";
import user from "./user";
import monetizationBundle from "./monetization-bundle";
import _module from "./module";
import archive from "./archive";
import card from './card';
import contract from "./contract";
import affiliate from "./affiliate";
import message from "./message";
import menu from "./menu";
import media from './media';
import page from "./page";
import blockEditor from "./block-editor";
import dashboard from "./dashboard";
import account from "./account";
import content_settings from "./content-settings";
import analytics from "./analytics";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    notification,
    helper,
    user,
    monetizationBundle,
    _module,
    archive,
    card,
    contract,
    affiliate,
    message,
    menu,
    media,
    page,
    blockEditor,
    dashboard,
    account,
    content_settings,
    analytics
  }
})
