export default [
    {
        path: "/dashboard/messages",
        name: "messages",
        meta: {
            relation: "messages"
        },
        components: {
            dashboard: () => import("@/views/Dashboard/Messages/Overview.vue")
        },
        children: [
            {
                path: "/dashboard/messages/create",
                name: "create-message",
                meta: {
                    relation: "messages",
                },
                components: {
                    create_message: () => import("@/views/Dashboard/Common/CreateEntity.vue"),
                },
            },
        ],
    },
    {
        path: "/dashboard/messages/:id",
        name: "message-container",
        meta: {
            relation: "messages"
        },
        components: {
            dashboard: () => import("@/views/Dashboard/Messages/Single/Container.vue")
        },
        children: [
            {
                path: "/dashboard/message/:id/recipients",
                name: "message-recipients",
                meta: {
                    relation: "messages"
                },
                components: {
                    message_container: () => import("@/views/Dashboard/Messages/Single/Recipients.vue")
                }
            },
            {
                path: "/dashboard/message/:id/settings",
                name: "message-settings",
                meta: {
                    relation: "messages"
                },
                components: {
                    message_container: () => import("@/views/Dashboard/Messages/Single/Settings.vue")
                },
                children: [
                    {
                        path: "/dashboard/message/:id/settings/notes",
                        name: "message-settings-notes",
                        meta: {
                            relation: "messages"
                        },
                        components: {
                            message_settings_notes: () => import("@/views/Dashboard/Messages/Single/Notes.vue")
                        }
                    },
                ]
            },
        ]
    },
]
