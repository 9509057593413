'use strict';

export default [
	{
		path: '/dashboard/archive',
		name: 'archive',
		meta: {
			relation: 'archive',
			help: 'archive'
		},
		components: {
			dashboard: () => import('@/views/Dashboard/Settings/Archive.vue')
		},
	},
]