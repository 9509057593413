'use strict'

import axios from "axios";

export default {
    getPage: (state, payload) => {
        return new Promise(done => {
            axios.get(`/page/render/${payload.qualifier}`).then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    getStructure: (state, payload) => {
        return new Promise(done => {
            axios.get(`/page/routing`).then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    listBlocks: (state,payload) => {
        return new Promise(done => {
            axios.get(`/system/blocks`).then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    }
}