import axios from "axios";

export default {
    getAnalyticsByParameter: ( state , data ) => {
        let query = '';

        if (data.query) {
            for (const [param, value] of Object.entries(data.query)) {
                if (!query.length) {
                    query = `?${param}=${value}`
                } else {
                    query += `&${param}=${value}`
                }
            }
        }
        
        return new Promise( done => {
            axios.get( `analytics/present/property/${data.type}/${data.from}/${data.to}${query}` )
                .then(res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    }
}