import axios from "axios";

export default {
    // getters
    getPageForEditor: (state,payload) => {
        return new Promise( done => {
            let queryUrl = `/page/single/${payload.id}`;
            if( payload.mode ){
                queryUrl += `?mode=${payload.mode}`
            }
            axios.get( queryUrl ).then( res => {
                if( res.status === 200 && res.data && res.data.success ){
                    state.commit( 'getPageForEditor', res.data.data );
                    if( !payload.fieldsToRefresh && !Array.isArray( payload.fieldsToRefresh ) )
                    {
                        state.commit( 'blockEditor/setSingleEntity', res.data.data, {
                            root: true
                        } );
                    }else if( Array.isArray( payload.fieldsToRefresh ) ){
                        payload.fieldsToRefresh.forEach( ( field ) => {

                            let value = res.data.data[field];
                            let _id;

                            if( res.data.data[field] && typeof res.data.data[field].value !== 'undefined' && res.data.data[field].value !== null ){
                                value = res.data.data[field].value;
                                _id = res.data.data[field]._id;
                            }
                            
                            if( typeof value !== 'undefined' ){
                                state.commit( 'blockEditor/setEntityPropValue', { key: field, value: value, _id: _id }, {
                                    root: true
                                } );
                            }
                        });
                    }
                }
                done( res.data );
            }).catch(err => {
                done( err );
            });
        });
    },
    getPagesList: (state,payload) => {
        return new Promise( done => {
            let urlParameter = '';
            if ( payload.searchTerm || payload.titleId || payload.excludeId ){
                let urlParameterArray = [];
                if (payload.searchTerm ) urlParameterArray.push(`search=${payload.searchTerm}`)
                if (payload.titleId ) urlParameterArray.push(`title=${payload.titleId}`)
                if (payload.excludeId ) urlParameterArray.push(`excludeId=${payload.excludeId}`)
                urlParameter = `?${urlParameterArray.join('&')}`
            }
            axios.get( `/page/list/${payload.page}${urlParameter}` )
            .then(res => {
                if( res.data && res.data.success ){
                    state.commit( 'getPages', res.data.data );
                }
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    // setters
    createPage: (state,payload) => {
        return new Promise( done => {
            axios.post( `/page/create`, payload )
            .then(res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )  
    },
    updatePage: (state,payload) => {
        return new Promise( done => {
            axios.post( `/page/edit`, payload )
            .then(res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )  
    },
    editTag: ( context, data ) => {
		return new Promise(done => {
			axios.post( '/page/tags', data )
			.then( res => done( res.data ) )
			.catch( err => done( err ))
		})
	},
    setActiveState: ( context, data ) => {
		return new Promise(done => {
			axios.post( '/page/activation', data )
			.then( res => done( res.data ) )
			.catch( err => done( err ))
		})
	},
    archive: (context, data) => {
        return new Promise((done) => {
            axios
            .post(`/page/archive`, data)
            .then((res) => {
                done(res.data);
            })
            .catch((err) => done(err));
        });
    }
}