export default {
	activeBlock: {},
	singleEntity: {
		body:{
			value:{
				data: {},
				structure: [],
				slots: {}
			}
		}
	},
	currentSlot: '',
	changesCount: 0,
	slotsChangesCount: 0,
	blocksList: {},
	categories: {}
}