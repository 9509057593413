'use strict'

import deepClone from '@/services/deep-clone';
import Vue from 'vue';

/* initializes body of a current object */
function initializeBody( state ) {
	// in case we are on page object, we need to initialize default object set in slot
	if( state.currentSlot !== '' && ( !state.singleEntity.body[state.currentSlot] || typeof state.singleEntity.body[state.currentSlot] !== 'object' || typeof state.singleEntity.body[state.currentSlot].value !== 'object' ) ){
		state.singleEntity.body[state.currentSlot] = {};
	}

	if( state.currentSlot !== '' && ( !state.singleEntity.body[state.currentSlot].value || Object.keys( state.singleEntity.body[state.currentSlot].value ) == 0 ) ){
		state.singleEntity.body[state.currentSlot].value = {
			structure: [],
			data: {}
		};
	}
}

/* Recursively adds child from the payload to children array of provided parent (dataId), uses recursion to access needed parent */
function updateStructureChild( obj, parentDataId, payload, newBlockObject ) {
	if( obj.children && !Array.isArray( obj.children ) ){
		obj.children = Object.values( obj.children );
	}
	// block id matches needed parent id, update children array
	if( obj && obj.dataId === parentDataId ){
		if( !obj.children ){
			obj.children = [];
		}
		// appends new block at position and moves other blocks if needed
		if ( !payload.absolute && payload.data[1] ) {
			obj.children.splice( payload.data[1], 1 );
			if ( parseInt( payload.data[1] ) < parseInt( payload.dropArea[1] ) ) {
				obj.children.splice( parseInt( payload.dropArea[1] ) - 1, 0, newBlockObject );
			} else {
				obj.children.splice( payload.dropArea[1], 0, newBlockObject );
			}
		// appends new block 
		} else if( !payload.absolute ) {
			obj.children.splice( payload.dropArea[1], 0, newBlockObject );
		// replaces entire children array if 'absolute' option is set
		} else if( payload.absolute ){
			obj.children = payload.data;
		}
	// no id match and block has children array, loop through all children
	}else if( obj.children ){
		obj.children.forEach( function( child ) {
			updateStructureChild( child, parentDataId, payload, newBlockObject );
		} );
	}
}
/* Recursively removes child from parent block */
function removeStructureChild( obj, childrenDataId ) {
	if( obj.children && !Array.isArray( obj.children ) ){
		obj.children = Object.values( obj.children );
	}
	let found = false;
	if( obj.children ){
		obj.children.forEach( ( item, idx ) => {
			if( found ) return;
			if ( item.dataId === childrenDataId ) {
				obj.children.splice( idx, 1 );
				found = true;
			}
		} );
	}
	if( !found && obj.children ){
		obj.children.forEach( function( child ) {
			removeStructureChild( child, childrenDataId )
		} );
	}
}
  
export default {
	setLanguage: (state, payload) => {

    },
    getLanguage: (state, payload) => {
        
    },
	setBlocksList:( state, payload ) => {
		if( payload.blocks && payload.categories ){
			state.blocksList = JSON.parse( JSON.stringify( payload.blocks ) );
			state.categories = JSON.parse( JSON.stringify( payload.categories ) );
		}
	},
	// this function sets object property value in object stored in state
	// has an exlusion in case slotId is provided, we need to append new value in body for provided slotId(so we do not refresh entire body prop)
	setEntityPropValue: (state,payload) => {
		if( typeof state.singleEntity[ payload.key ] !== 'undefined' ){
			if( payload.slotId && payload.slotId !== '' ){
				if( state.singleEntity[ payload.key ] && !state.singleEntity[ payload.key ][ payload.slotId ] ){
					state.singleEntity[ payload.key ][ payload.slotId ] = {
						value: {}
					}
				}
				state.singleEntity[ payload.key ][ payload.slotId ].value = payload.value;
				if( payload._id ){
					state.singleEntity[ payload.key ][ payload.slotId ]._id = payload._id;
				}

				if( payload.value === '__delete' ){
					delete state.singleEntity[ payload.key ][ payload.slotId ];
				}

			}else if( typeof state.singleEntity[ payload.key ].value !== 'undefined' ) {
				state.singleEntity[ payload.key ].value = payload.value;
				if( payload._id ){
					state.singleEntity[ payload.key ]._id = payload._id;
				}
			}else{
				state.singleEntity[ payload.key ] = payload.value;
				if( payload._id ){
					state.singleEntity[ payload.key ]._id = payload._id;
				}
			}
		}
	},
	// this function sets 'singleEntity' variable value in state
	setSingleEntity: (state,payload) => {
		payload = JSON.parse( JSON.stringify ( payload ) );

		state.singleEntity = payload;

		// initialize body if it is not set
		if( !state.singleEntity.body ){
			state.singleEntity.body = {}
		}
	},
	// this function updates the structure of page/object
	updateStructure: ( state, payload ) => {

		// in case we are on page object, we need to initialize default object set in slot
		initializeBody( state );

		// if we want to replace current structure with the one from payload absolutely
		if( payload.absolute ){

			if( Array.isArray( payload.data ) ){
				payload.data = Object.assign( {}, payload.data);
			}
			
			// absolutely updates the structure if there is no parent provided in current payload
			if( !payload.parent || payload.parent === '' || typeof payload.parent !== 'string' ){
				if( state.currentSlot === '' ){
					state.singleEntity.body.value.structure = payload.data;
				}else{
					state.singleEntity.body[state.currentSlot].value.structure = payload.data;
				}
			}
			// otherwise if there is parent provided in payload, we need to absolutely update the structure only inside of parent block (children property)
			else{
				if( state.currentSlot === '' ){
					// make sure that structure is an array and loop through the items in structure to find parent element by dataId and then just replace children array with the one from payload.data
					state.singleEntity.body.value.structure = Object.values( state.singleEntity.body.value.structure );
					for( let i = 0; i < state.singleEntity.body.value.structure.length; i ++) {
						updateStructureChild( state.singleEntity.body.value.structure[ i ], payload.parent, payload );
					}
				}else{
					// make sure that structure is an array and loop through the items in structure to find parent element by dataId and then just replace children array with the one from payload.data
					state.singleEntity.body[state.currentSlot].value.structure = Object.values( state.singleEntity.body[state.currentSlot].value.structure );
					for( let i = 0; i < state.singleEntity.body[state.currentSlot].value.structure.length; i ++) {
						updateStructureChild( state.singleEntity.body[state.currentSlot].value.structure[ i ], payload.parent, payload );
					}
				}
			}
			return;
		}

		let kind = 'block';

		// only valid for 'template' object that supports slots block
		if( payload.block && payload.block.is_slot ){
			kind = 'slot';
		}

		// set block kind from block config, otherwise kind would be 'block'
		if( payload.block && payload.block.kind ){
			kind = payload.block.kind;
		}

		let newBlockObject = { component: payload.data[0], dataId: payload.id, kind: kind };

		// in case current added block is slot we set slotId to be the same as dataId
		if( kind === 'slot' ){
			newBlockObject.slotId = payload.id;
		}

		let parent = payload.parent ? payload.parent : null;

		// grab current structure, either form current slot or global if current slot is not set(reminder: currentSlot is only valid for page object (non-template))
		let structure = state.currentSlot === '' ? state.singleEntity.body.value.structure : state.singleEntity.body[state.currentSlot].value.structure;

		if( !structure ) structure = [];

		if( !Array.isArray( structure ) ){
			structure = Object.values( structure );
		}

		// if parent is set then we are nesting blocks
		if( !parent ) {
			// add block to array / structure
			if ( payload.data[1] ) {
				structure.splice( payload.data[1], 1 );
				if ( parseInt( payload.data[1] ) < parseInt( payload.dropArea[1] ) ) {
					structure.splice( parseInt( payload.dropArea[1] ) - 1, 0, newBlockObject );
				} else {
					structure.splice( payload.dropArea[1], 0, newBlockObject );
				}
			} else {
				structure.splice( payload.dropArea[1], 0, newBlockObject );
			}
		} else{
			structure.forEach( function( structureEl ) {
				updateStructureChild( structureEl, parent, payload, newBlockObject )
			} );
		}

		// replace the structure, either global one or under slot( if we are under page )
		state.currentSlot === '' ? (state.singleEntity.body.value.structure = Object.assign( {}, structure ) ) : (state.singleEntity.body[state.currentSlot].value.structure = Object.assign( {}, structure ) );

		// create empty data object for added block
		if( state.currentSlot === '' ){
			if( !state.singleEntity.body.value.data ) state.singleEntity.body.value.data = {};
			if( !state.singleEntity.body.value.slots ) state.singleEntity.body.value.slots = {};
			
			// if kind is not slot(i.e it is block then we create empty object for storing its values in data object)
			if( kind !== 'slot' && !state.singleEntity.body.value.data[payload.id] ){
				state.singleEntity.body.value.data[payload.id] = {};
			}
			// in case we are in template editor, addd slot id to slots object(as a property)
			else if( !state.singleEntity.body.value.slots[payload.id] ){
				state.singleEntity.body.value.slots[payload.id] = '';
			}
		}else{
			// create empty data object for added block for current slot
			if( !state.singleEntity.body[state.currentSlot].value.data ) state.singleEntity.body[state.currentSlot].value.data = {};
		
			if( !state.singleEntity.body[state.currentSlot].value.data[payload.id] ){
				state.singleEntity.body[state.currentSlot].value.data[payload.id] = {};
			}
		}
	},
	// this function updates data for specified block via dataId
	updateData: ( state, payload ) => {

		if( typeof payload.value === 'object' ){
			payload.value = deepClone( payload.value );
		}

		initializeBody( state );

		// initialize global data if there is no slot and data object is not set
		if( state.currentSlot === '' && !state.singleEntity.body.value.data ) state.singleEntity.body.value.data = {};
		// initialize slot data if it's not set
		if( state.currentSlot !== '' && !state.singleEntity.body[state.currentSlot].value.data ) state.singleEntity.body[state.currentSlot].value.data = {};
		
		// take current data(object) value
		let data = state.currentSlot === '' ? state.singleEntity.body.value.data : state.singleEntity.body[state.currentSlot].value.data;

		if( !payload.dataId ){
			console.log( payload );
			console.error( 'no_dataId' );
			return;
		}

		// update current data with new value
		if( data[payload.dataId] ){
			if( data[payload.dataId][payload.key] !== 'undefined' ){
				data[payload.dataId][payload.key] = payload.value;
			}
		}else{
			data[payload.dataId] = {
				[payload.key]: payload.value
			}
		}

		// store new data object value to state
		state.currentSlot === '' ? state.singleEntity.body.value.data = data : state.singleEntity.body[state.currentSlot].value.data = data
	},
	// only used in TEMPLATE MODE to fill structure with empty slots
	updateSlot: ( state, payload ) => {

		if( state.currentSlot != '' ) return; 

		if( !state.singleEntity.body.value.slots ) state.singleEntity.body.value.slots = {};

		let slots = state.singleEntity.body.value.slots;

		if(!payload.dataId) 
		{	
			console.error('no_dataId');
			return;
		}

		if( !slots[payload.dataId] ){
			slots[payload.dataId] = '';
		}

		slots[payload.dataId] = payload.value;

		state.singleEntity.body.value.slots = slots;
	},
	// only used in page mode to update state variable value
	setCurrentSlot( state, payload ){
		state.currentSlot = payload;
	},
	// removes specified block from structure (i.e deletes it)
	deleteBlockFromStructure: ( state, payload ) => {
		// grab current structure, either form current slot or global if current slot is not set(reminder: currentSlot is only valid for page object (non-template))
		let structure = state.currentSlot === '' ? state.singleEntity.body.value.structure : state.singleEntity.body[state.currentSlot].value.structure;

		if(!structure) structure = [];

		if( !Array.isArray( structure ) ){
			structure = Object.values( structure );
		}

		let removeDataAndSlots = () => {
			// delete data for block that is being deleted, either from slot or from global data object
			// (from slot only if it is set, i.e we are in page mode)
			if ( state.currentSlot === '' && state.singleEntity.body.value.data && state.singleEntity.body.value.data[payload] ){
				delete state.singleEntity.body.value.data[payload];
			} else if ( state.currentSlot !== '' && state.singleEntity.body[state.currentSlot].value && state.singleEntity.body[state.currentSlot].value.data && state.singleEntity.body[state.currentSlot].value.data[payload] ){
				delete state.singleEntity.body[state.currentSlot].value.data[payload];
			}

			if ( state.singleEntity.body.value && state.singleEntity.body.value.slots && state.singleEntity.body.value.slots[payload] )
			{
				delete state.singleEntity.body.value.slots[payload];
			}
		};
		// if there is no _id_ it means block is not nested
		if( !payload.includes( '_id_' ) ){
			// loop through the structure and find needed block by dataId
			structure.forEach( ( item, idx ) => {
				if ( item.dataId === payload ) {
					structure.splice( idx, 1 );
					// remove data and slots if needed 
					removeDataAndSlots();
				}
			} );
		}
		// otherwise if payload contains _id_ it means that the block is nested and we need to remove it from children structure of the parent block
		else{
			structure.forEach( ( item ) => {
				removeStructureChild( item, payload );
			} );
			removeDataAndSlots();
		}
		
		// replace the structure in the state, either global one or under slot( if we are under page )
		if( state.currentSlot !== '' && Array.isArray( structure ) ) {
			state.singleEntity.body[state.currentSlot].value.structure = structure
		}
		else if( state.currentSlot === '' ){
			state.singleEntity.body.value.structure = structure;
		}
	},
	updateActiveBlock: ( state, payload ) => {
		if( payload ){
			state.activeBlock = payload;
		}
	},
	setChangesCount: ( state, payload ) => state.changesCount = payload,
	setSlotChangesCount: ( state, payload ) => state.slotsChangesCount = payload,
	deleteRevision: ( state, payload ) => {
        let idx = state.singleEntity[ payload.status ][ payload.key ].findIndex( item => item._id === payload.id );

        if ( idx >= 0 ) Vue.delete(state.singleEntity[ payload.status ][ payload.key ], idx)
    },
}