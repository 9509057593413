import countChanges from "@/services/count-changes";

export default {
    createMediaData: state => {
        return state.createMediaData;
    },

    countContentChanges: state => {
        return countChanges( state.stateSingleData, state.singleData);
    }
}