export default {
    updateContractsList: (state, payload) => state.contractList = payload,
    
    updateSingleContract: (state, payload) => {
        state.singleData = payload;
        state.stateSingleData = payload;
    },

    updateLocalContractData: (state, payload) => {
        state.stateSingleData = payload;
    },

    updateSingleData: (state, payload) => {
        state.singleData.accountRecipient = payload.accountRecipient;
        state.singleData.adminRecipient = payload.adminRecipient;
        state.singleData.dateOfEffect = payload.dateOfEffect;
        state.singleData.recipients = payload.recipients;
    },

    updateCreateContractData: (state, payload) => state.createContractData = payload,

    clearContractData: (state) => {
        state.singleData = {};
        state.stateSingleData = {};
    }
};