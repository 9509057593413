import axios from "axios";
import store from '@/store'

export default {
	popupSelectGetTitles:( context, data ) => {
		if ( context.state.popupSelectTimer != null ) clearTimeout( context.state.popupSelectTimer );
		if ( data ) {
			context.commit( 'setPopupSelectIds', data );
			context.commit( 'setPopupSelectTimer', setTimeout( () => {
				return new Promise( done => {
					axios.post( `/popup-select/get-titles`, { ids: context.state.popupSelectIds } )
					.then( res => {
						done( res.data )
						context.commit( 'setPopupSelectTitles', res.data.data );
					})
					.catch( err => done( err ) );
				})
			}, 100 )
			)
		}
	},
	getCountries: ( context, data ) => {
		return new Promise(done => {
            axios.get(`/system/get-countries`, { baseURL: '/api/p' })
                .then(res => {
                    if (res.data.success) context.commit('setCountries' , res.data.data);

                    done(res.data)
                }).catch(err => {
                    done(err);
            })
        })
	},
	getBasic: ( context ) => {
		return new Promise(done => {

        	let request;
        	let user = store.getters['user/get'];
        	if(user.role == 'admin') request = axios.get('/basic');
        	else if(user.role == 'account') request= axios.get('/account/basic', { baseURL: '/api/r' });

            request.then(res => {
                    if(res.data.dashboardMenu) context.commit('setDashboardMenu' , res.data.dashboardMenu);

					if(res.data.instanceConfig) context.commit('setFeatures' , res.data.instanceConfig.features );
					else context.commit('setFeatures' , res.data.data.instanceConfig.features );

					if (res.data.admin_roles) {
						context.commit('setAdminRoles' , res.data.admin_roles);
					}

                    if (res.data.contracts) {
						context.commit('setContracts' , res.data.contracts);
					}

					if (res.data.user_id) {
						context.commit('setUserId' , res.data.user_id);
					}

					if (res.data.allowedMediaTypes) {
						context.commit('setAllowedMediaTypes' , res.data.allowedMediaTypes);
					}

					if (res.data.monetizationBundle) {
						context.commit('setMonetizationBundle', res.data.monetizationBundle);
					}

					if (res.data.stripe) {
						context.commit('setStripe', res.data.stripe);
					}

					context.commit('setBasicDataLoaded', true);

                    done(res.data)
                }).catch(err => {
                    done(err);
            })
        })
	}
}