<template>
    <div class="popup-select-input">
        <FormulateInput
            type="text"
            class="popup-select-input__input"
            :value="selectedValue._id"
            :label="selectedValue.title ? selectedValue.title : context.attributes.title"
        />
        <Button
            :variant="button.variant ? button.variant : 'btn-orange'"
            :label="button.label"
            :action="button.action"
        />
    </div>
</template>

<script>
import Button from "@/components/Buttons/Button";

export default {
    name: "PopupSelectInput",
	components: {
		Button,
	},
    props: {
        label: {
            type: String,
            default: ''
        },
        context: {
            type: Object,
            required: true
        },
    },
    computed: {
        button() {
            if ( this.context.attributes.button ) return this.context.attributes.button;
            return {
                label: '',
                variant: 'btn-orange',
                action: () => {}
            };
        },
        selectedValue() {
            return this.$store.state.helper.popupSelectValue;
        },
    },
    updated() {
        if ( this.selectedValue._id ) {
            this.context.model = this.selectedValue._id;
        }
        else this.context.model = '';
    },
};
</script>

<style lang="scss" scoped>

.popup-select-input {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    // "align-items: baseline" doesn't work in firefox
    @supports (-moz-appearance: none) {
        align-items: center;
    }

    button {
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 5px 15px;
        font-weight: 600;
    }

    &__input {
        flex-grow: 1;

        ::v-deep &.formulate-input {
            margin-bottom: 0;

            .formulate-input-wrapper {
                position: relative;

                label {
                    font-weight: normal;
                    font-size: 0.875rem;
                    line-height: 160%;
                    color: $dark;
                    margin-bottom: 0;
                    position: absolute;
                    left: 15px;
                    right: 15px;
                    top: 1.1rem;
                    height: 0.875rem;
                    overflow: hidden;
                    line-height: 100%;

                    @media (min-width: 992px) {
                        top: 1rem;
                    }
                }

                .formulate-input-element {
                    margin-bottom: 0;

                    input {
                        font-weight: normal;
                        font-size: 0.875rem;
                        line-height: 160%;
                        color: transparent;
                        padding: 11px 95px 11px 15px;
                        min-width: 210px;
                        width: 100%;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
