/**
 * /account/bundles/:group
 * /account/bundle/:monetization_bundle_id
 * /account/bundle/:monetization_bundle_id/bookmark/create
 **/

export default [
  {
    path: "bundles/:group",
    name: "bundles",
    meta: {
      relation: "bundles",
    },
    components: {
      client: () => import("@/views/Account/Bundles.vue"),
    },
  },
  {
    path: "bundle/:monetization_bundle_id",
    name: "bundle",
    meta: {
      relation: "bundles",
    },
    components: {
      client: () => import("@/views/Account/Bundles/Bundle.vue"),
    },
    children: [
      {
        path: "bookmark/create/:module_id",
        name: "bundle-bookmark-create",
        meta: {
          relation: "bundles",
        },
        components: {
          bundle: () => import("@/views/Account/Bookmarks/Create.vue"),
        },
      },
    ],
  },
  {
    path: "bundle/:id/checkout",
    name: "bundle-checkout",
    meta: {
      relation: "bundles",
    },
    components: {
      client: () => import("@/views/Account/Bundles/Checkout.vue"),
    },
  },
];
