import languages from "./languages";

import select from "./languages/select"

export default {
	hasPermissions: (state) => (desiredPermissions) => {
		if(state.admin_roles){
			if ( typeof desiredPermissions === 'string' ) {
				if (state.admin_roles.includes(desiredPermissions)) return true;
			}

			if ( Array.isArray( desiredPermissions ) ) {
				return desiredPermissions.some( (permission) => {
					if (state.admin_roles.includes(permission)) {
						return true;
					}
				} );
			}

			return false;
		}else return false
	},

	userId(state) {
		return state.user_id
	},
	labels(state){
		return languages[select]
	}
}