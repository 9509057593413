import Vue from "vue";

export default {

	setXFormStatus: (state, data) => state.xFormStatus = data,

	setShowXActions: (state, data) => state.showXActions = data,

	clearChangedSettings: (state) => state.changedSettings = [],

	clearChangedMain: (state) => state.changedMain = [],

	setStatusLoadingSkeleton: ( state, payload ) => state.statusLoadingSkeleton = payload,

	updateMediaList: ( state, data ) => state.mediaList = data,

	updateOverviewPermissions: ( state, permissions ) => state.overviewPermissions = permissions,

	updateSingleMedia: ( state, payload ) => {
		// Setting it as empty object merged with payload is required to avoid situations when later both states are getting changed when you only change one.
		state.singleData = {...payload};
        state.stateSingleData = {...payload};
	},

	updateStateSingleMediaByKey: ( state, obj ) => {
		state.stateSingleData[ obj.key ] = obj.value;
	},

	updateChangedSettings: ( state, data ) => {
		let index = state.changedSettings.findIndex(value => value.key === data.key);

		if ( state.singleMedia.media && state.singleMedia.media[ data.key ] ) {
			if ( index >= 0 ) {
				if ( state.singleMedia.media[ data.key ].value === data.value ) {
					state.changedSettings.splice( index, 1 );
				} else {
					state.changedSettings[index] = data;
				}
			}else {
				state.changedSettings.push( data );
			}
		}
	},

	updateChangedMain: ( state, data ) => {
		let index = state.changedMain.findIndex(value => value.key === data.key);

		if ( state.singleMedia.media && state.singleMedia.media[ data.key ] ) {
			if ( index >= 0 ) {
				if ( state.singleMedia.media[ data.key ].value === data.value ) {
					state.changedMain.splice( index, 1 );
				} else {
					state.changedMain[index] = data;
				}
			}else {
				state.changedMain.push( data );
			}
		}
	},
	deleteRevision: ( state, payload ) => {
		let idx = state.singleMedia[ payload.status ][ payload.key ].findIndex( item => item._id === payload.id );

        if ( idx >= 0 ) Vue.delete(state.singleMedia[ payload.status ][ payload.key ], idx)
	},

	clearSingleData: ( state ) => {
		state.singleData = {};
		state.stateSingleData = {};
	}
}