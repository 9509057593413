export default {

    runNotification: ( state, payload )=> {
        state.notifications = { ...payload }
    },

    clearNotification:(state)=> state.notifications = {},

    removeSingleNotification:(state,payload={})=>{
        let index = payload.index;
        state.notifications.splice(index,1)
    },

    // addNotification:(state, payload={})=>{
    //     if(payload.variant && payload.msg ) {
    //
    //         let notification = {
    //             msg: []
    //         }
    //         for ( let prop in payload ) {
    //             if ( prop !== 'msg' && Object.hasOwnProperty.call( payload, prop ) ) {
    //                 notification[prop] = payload[prop];
    //             }
    //         }
    //
    //         if ( typeof payload.msg === "string" ) {
    //             notification.msg.push( payload.msg );
    //         }
    //         else if ( Array.isArray(payload.msg) ) {
    //             notification.msg = payload.msg;
    //         }
    //         else if ( typeof payload.msg === "object" && !Array.isArray(payload.msg) && payload.msg !== null ) {
    //             notification.msg.push( Object.values( payload.msg ) );
    //         }
    //
    //         notification.msg.forEach( msg => {
    //             if ( payload.labels && payload.labels[msg] ) {
    //                 notification.msg = payload.labels[msg];
    //                 state.notifications = notification;
    //                 // setNotificationTimeout( state, notification );
    //             } else {
    //                 notification.msg = msg;
    //                 state.notifications = notification;
    //                 // setNotificationTimeout( state, notification );
    //             }
    //         } );
    //     }
    // }

}