import countChanges from "@/services/count-changes";

export default {
    moduleList: state => {
        return state.moduleList;
    },

    countContentChanges: state => {
        let counter = 0;
        state.moduleContent.forEach( ( item, idx ) => {
            let el = state.stateModuleContent[idx];
            if ( el && el._id === item._id ) {
                if ( JSON.stringify(el.data) !== JSON.stringify(item.data) ) counter++;
            } else counter++;
        } );
        let output = {
            saveBtnDisabled: true,
            changedKeys: ['content']
        }
        if ( counter || state.moduleContent.length !== state.stateModuleContent.length ) output.saveBtnDisabled = false;
        return output;
    },

    countSettingsChanges: state => countChanges( state.singleData, state.stateSingleData ),
}