import axios from "axios";

export default {
	create: ( state, data ) => {
		return new Promise( done => {
			axios.post( '/media/create', data )
			.then( res => done( res.data ) )
			.catch( err => done( err ) )
		} )
	},
	getList: ( state, data ) => {
		return new Promise( done => {
			let urlParameter = '';
			if ( data.searchTerm || data.titleId || data.excludeId || data.user ){
				let urlParameterArray = [];
				if ( data.user ) urlParameterArray.push(`user=1`);
				if ( data.searchTerm ) urlParameterArray.push(`search=${data.searchTerm}`);
				if ( data.titleId ) urlParameterArray.push(`title=${data.titleId}`);
				if ( data.excludeId ) urlParameterArray.push(`excludeId=${data.excludeId}`);
				urlParameter = `?${urlParameterArray.join('&')}`;
			}
			axios.get( `/media/list/${ data.page }${ urlParameter }` )
			.then( res => {
				state.commit( 'updateMediaList', res.data.data.data )
				if ( res.data.data.permissions ) {
					state.commit( 'updateOverviewPermissions', res.data.data.permissions );
				}
				done( res.data )
			} )
			.catch( err => done( err ) )
		} )
	},
	getSingle: ( state , id ) => {
		return new Promise( done => {
			axios.get( `/media/single/${id}`)
			.then( res => {
				if (res.data.success) state.commit( 'updateSingleMedia', res.data.data )
				done( res.data )
			} )
			.catch( err => done( err ) )
		})
	},
	archive: ( state , data) => {
		return new Promise( done => {
			axios.post( '/media/archive', data )
			.then( res =>  done( res.data ) )
			.catch( err => done( err ) )
		} )
	},
	update: ( state , data ) => {
		return new Promise( done => {
			axios.post( '/media/edit', data )
			.then( res => done( res.data ) )
			.catch( err => done( err ) )
		} )
	},
	editTag: ( state, data ) => {
		return new Promise( done => {
			axios.post( '/media/tag', data )
			.then( res => done( res.data ) )
			.catch( err => done( err ) )
		})
	},
	getMediaByTag: ( context, data ) => {
		return new Promise(done => {
			axios.get( `/media/list-by-tag/${data.page}/${data.tags}`, data )
			.then( res => {
				context.commit( 'updateMediaList', res.data.data.data )
				done( res.data );
			} )
			.catch( err => done( err ))
		})
	},
	archive: (context, data) => {
        return new Promise((done) => {
            axios
            .post(`/page/archive`, data)
            .then((res) => {
                done(res.data);
            })
            .catch((err) => done(err));
        });
    }
}