export default {
  updateFromModule: (state, data) => {
    state.module = data.module;
    state.authors = data.authors;
    state.monetizationBundle = data.monetizationBundle;
    state.remarks = data.remarks;
    state.bookmarks = data.bookmarks;
    state.medias = data.medias;
    state.cards = data.cards;
  },
  updateMonetizationBundles: (state, data) => (state.monetizationBundles = data),
  setRemark: (state, data) => (state.remark = data),
  addRemark: (state, data) => (state.remarks.push(data)),
  updateRemark: (state, data) => {
    if (data.text && data.title) {
      state.remark = {
        ...state.remark,
        ...{
          title: data.title,
          text: data.text
        }
      }

      // If remarks already loaded in array, find the one we edited and update
      if (!state.remarks?.length) return;

      const remarkIndex = state.remarks.findIndex(x => x._id == state.remark._id);

      if (remarkIndex !== -1) state.remarks[remarkIndex] = {
        ...state.remark,
        ...{
          title: data.title,
          text: data.text
        }
      };
    }
  },
  removeRemark: (state, id) => {
    // If current loaded remark is the one we need to delete - remove it
    if (state.remark?._id == id) state.remark = {};

    // If remarks already loaded in array, find the one we want to remove and remove it
    if (!state.remarks?.length) return;

    const remarkIndex = state.remarks.findIndex(x => x._id == id);

    if (remarkIndex !== -1) state.remarks.splice(remarkIndex, 1);
  },
  updateBookmarks: (state, data) => (state.bookmarks = data),
  setBookmark: (state, data) => (state.bookmark = data),
  updateBookmark: (state, data) => {
    if (data.text) {
      state.bookmark = {
        ...state.bookmark,
        ...{
          text: data.text
        }
      }

      // If bookmarks already loaded in array, find the one we edited and update
      if (!state.bookmarks?.length) return;

      const bookmarkIndex = state.bookmarks.findIndex(x => x._id == state.bookmark._id);

      if (bookmarkIndex !== -1) state.bookmarks[bookmarkIndex] = {
        ...state.bookmark,
        ...{
          text: data.text
        }
      };
    }
  },
  removeBookmark: (state, id) => {
    // If current loaded bookmark is the one we need to delete - remove it
    if (state.bookmark?._id == id) state.bookmark = {};

    // If remarks already loaded in array, find the one we want to remove and remove it
    if (!state.bookmarks?.length) return;

    const bookmarkIndex = state.bookmarks.findIndex(x => x._id == id);

    if (bookmarkIndex !== -1) state.bookmarks.splice(bookmarkIndex, 1);
  },
};
