<template>
  <button
    type="button"
    :class="[`custom-button inline-block`, variant]"
    :style="style"
    :disabled="disabled"
    @click="action(), click()"
  >
    <slot></slot>
    <font-awesome-icon
      :icon="icon"
      class="mr-2"
      :class="{ times: icon === 'times' }"
      v-if="icon && iconPosition === 'left'"
    />
    <span class="text">{{ label }}</span>
    <font-awesome-icon
      :icon="icon"
      class="ml-3"
      :class="{ times: icon === 'times' }"
      v-if="icon && iconPosition === 'right'"
    />
  </button>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
const colors = require("/colors.config.json");
export default {
  name: "Button",
  data() {
    return {
      style: {
        "--color":
          this.bgColor == colors.navy && this.color == colors.black
            ? colors.white
            : this.color,
        "--background-color": this.bgColor,
        "--padding": this.padding,
        "--font-weight": this.fontWeight,
        "--font-size": this.fontSize,
        "--height": this.height,
        "--width": this.width,
        "--border-radius": this.borderRadius,
        "--filter": this.filter,
        "--opacity": this.opacity,
        ...this.styles,
      },
    };
  },
  props: {
    variant: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "11px 20px",
    },
    fontWeight: {
      type: String,
      default: "400",
    },
    fontSize: {
      type: String,
      default: "0.875rem",
    },
    bgColor: {
      type: String,
      default: "#f8f9fa",
    },
    height: {
      type: String,
      default: "auto",
    },
    width: {
      type: String,
      default: "auto",
    },
    borderRadius: {
      type: String,
      default: "5px",
    },
    color: {
      type: String,
      default: `${colors.black}`,
    },
    filter: {
      type: String,
      default: "brightness(80%)",
    },
    opacity: {
      type: String,
      default: "1",
    },
    styles: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Function,
      default: () => {},
    },
    icon: {
      type: String,
      default: "",
    },
    iconPosition: {
      type: String,
      default: "left",
    },
    to: {
      type: String,
      default: "",
    },
  },
  methods: {
    click() {
      if (!this.disabled) this.$emit("click");

      if (this.to.length) {
        window.location.href = this.to;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/button";

.custom-button {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  color: var(--color);
  transition: all 0.4s ease;
  font-weight: var(--font-weight);
  padding: var(--padding);
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 11px -1px #00000038;
  font-size: var(--font-size);
  height: var(--height);
  width: var(--width);

  &:hover {
    box-shadow: none;
    filter: var(--filter);
    opacity: var(--opacity);
  }

  .times {
    background: white;
    height: 20px;
    width: 20px;
    padding: 2px;
    border-radius: 100%;
  }

  .text {
    width: 100%;
    text-align: center;
  }
}

// @media (min-width: 1200px) {
//     .custom-button {
//         font-size: 14px;
//     }
// }

// @media (min-width: 1600px) {
//     .custom-button {
//         font-size: 16px;
//     }
// }
</style>
