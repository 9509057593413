import axios from "axios";

export default {
	generateText: (context, data) => {
		return new Promise(done => {
			axios.post('/generate-text', data)
				.then(res => done(res.data))
				.catch(err => done(err))
		})
	}
}