const colors = require("/colors.config.json");
export default {
    getDataArray(){
        return( data ) => {
            return data.map( el => {
                const container = {};
                container._id = el._id
                container.name = el.title
                container.description = el.description;
                if( el.description ) container.description = el.description;
                container.actions = [ {
                    color: `${colors.white}`,
                    bgColor: '#14AAF5',
                    icon: 'external-link-alt',
                    width: '32px',
                    height: '32px',
                    fontSize: '14px',
                    lineHeight: 'normal',
                    margin: '0px 10px 0px 0px',
                    passKey: 'name',
                    link: (id) => {
                        return `/dashboard/menu/${id}/`
                    },
                    action: () => {},
                }, ]
                return container;
            } );
        }
    }
}