import countChanges from "@/services/count-changes";

export default {
    contractList: state => {
        return state.contractList;
    },

    singleData: state => {
        return state.singleData;
    },

    stateSingleData: state => {
        return state.stateSingleData;
    },

    createContractData: state => {
        return state.createContractData;
    },

    countContentChanges: state => {
        
        /* Changes counter needs to be implemented for the contract since code below was copied from the module */
        
        /*
        let counter = 0;
        state.moduleContent.forEach( ( item, idx ) => {
            let el = state.stateModuleContent[idx];
            if ( el && el._id === item._id ) {
                if ( el.data !== item.data ) counter++;
            } else counter++;
        } );
        let output = {
            saveBtnDisabled: true,
            changedKeys: ['content']
        }
        if ( counter || state.moduleContent.length !== state.stateModuleContent.length ) output.saveBtnDisabled = false;
        return output;
        */

        return {
            saveBtnDisabled: false
        };
    },

    //countSettingsChanges: state => countChanges( state.singleData, state.stateSingleData ),

    countSettingsChanges: state => () => { return { saveBtnDisabled: false } }
}