/**
 * /account/bookmarks
 * /account/bookmark/:bookmark_id
 */
 export default [
	{
		path: 'bookmarks',
		name: 'bookmarks',
		meta: {
			relation: 'bookmarks',
		},
		components: {
			client: () => import ('@/views/Account/Bookmarks.vue')
		}
	},
	{
		path: 'bookmark/:bookmark_id',
		name: 'bookmark',
        meta: {
            relation: 'bookmarks',
        },
		components: {
			client: () => import('@/views/Account/Bookmarks/Bookmark.vue')
		}
	}
]