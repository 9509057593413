import axios from "axios";

export default {
    getList: ( context, data ) => {
        return new Promise( done => {
            axios.get( `/archive/list/${data.kind}/${data.page}` ).then( res => {
                done( res.data )
            } ).catch( err => {
                done( err );
            } )
        } )
    },
    restore: ( context, data ) => {
        return new Promise( done => {
            axios.post( `/${data.kind}/restore`, data )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    _delete: ( context, data ) => {
        return new Promise( done => {
            axios.post( `/${data.kind}/delete`, data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
}