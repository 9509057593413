import axios from "axios";

import pss from '@/services/persistent-state-storage'

export default {
    login: (state, credential) => {
        return new Promise(done => {
            axios.post('/login', credential, { baseURL: '/api/p' }).then(res => {

                state.commit('setUserIsAuthenticated', pss.set('userIsAuthenticated', true, {
                    isObject: true
                }))
                state.commit('setUserBaseUrl', pss.set('userBaseUrl', res.data.baseUrl))
                state.commit('setUserRole', pss.set('userRole', res.data.role))
                state.commit('setUsername', pss.set('username', res.data.username))
                state.commit('setUserFullName', pss.set('userFullName', res.data.name))
                state.commit('setUserid', pss.set('userid', res.data._id))

                done(res.data)
            }).catch(err => {
                console.log(err)
                done(err);
            })
        })
    },
    logout: () => {
        return new Promise(done => {
            axios.get('/logout', { baseURL: '/api/p' }).then( () => {
                localStorage.clear();
                var cookies = document.cookie.split(";");

                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
                location.reload()
            }).catch(err => {
                done(err);
            })
        })
    },
    getUsers: (context, obj = {}) => {
        return new Promise(done => {
            let urlParameter = '';

			if ( obj.kind ) urlParameter = `?kind=${obj.kind}`;
            if ( obj.adminRole ) urlParameter = `${(urlParameter=='' ? '?' : '')}adminRole=${obj.adminRole}`;

            if ( obj.excludeId ) urlParameter += `&excludeId=${obj.excludeId}`;

            axios.get(`/user/list/${obj.page}${urlParameter}`).then(res => {
                context.commit('setAllUsersData', res.data)
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    getSelectedUsers: (context, obj = {}) => {
        return new Promise(done => {
            axios.get(`/user/list-selected/${obj.collection}/${obj.page}`).then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    getUser: (context, id) => {
        console.log(id)
        return new Promise(done => {
            axios.get(`/user/single/${id}`)
                .then(res => {
                    context.commit('setSingleUserData' , res.data.data)
                    done(res.data)
                }).catch(err => {
                    done(err);
            })
        })
    },
    verify:(state,data )=>{
        return new Promise(done=>{
            axios.post(`/verification/${data.token}/${data.user}`, data, {
                baseURL: '/api/p'
            }).then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    verifyUser:(state,data )=>{
        return new Promise( done => {
            axios.post( '/verify', data, { baseURL: '/api/p' } )
               .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    signUp: ( context , data ) => {
        return new Promise(done => {
            axios.post( '/signup/' , data, { baseURL: '/api/p' })
                .then( res => {
                    // context.commit('updateUser', data);
                    done( res.data )
                } )
                .catch( err => done( err ) )
        })
    },
    editUser: (context , data) =>{
        return new Promise(done => {
            axios.post(`/user/edit`, data, { baseURL: '/api/ra' })
                .then( res => {
                    context.commit('setSingleUserData', data.payload);
                    done( res.data )
                } )
                .catch( err => done( err ) )
        })
    },
    createUser: ( context, data ) => {
        return new Promise( done => {
            axios.post( '/user/create', data, { baseURL: '/api/ra' } )
                .then( res => {
                    if (res.data.success) {
                        context.commit('addUserData', res.data.data);
                    }
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    setAdminRole: ( context, data ) => {
        return new Promise( done => {
            axios.post( '/user/set-admin-role', data, { baseURL: '/api/ra' } )
                .then( res => {
                    if (res.data.success) {
                        console.log(res);
                        // context.commit('setAdminRole', res.data.data);
                    }
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getManageUserList: (context, id) => {
        return new Promise(done => {
            axios.get(`/visibility-group/get-manage-user/${id}/`)
                .then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    updateManageUsers: (context, data) => {
        return new Promise(done => {
            axios.post(`/visibility-group/manage-users/`, data)
                .then(res => {
                    done(res.data)
                }).catch(err => {
                done(err);
            })
        })
    },
    resetPassord: ( state, data ) => {
        return new Promise( done => {
            axios.post( `/reset/${data.token}/${data.user}`, data, { baseURL: '/api/p' } )
                .then( res => {
                    done( res.data )
                } )           
                .catch( err => done( err ) )
        } )
    },
    forgot: ( state, data ) => {
        return new Promise( done => {
            axios.post( '/forgot', data, { baseURL: '/api/p' } )
               .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    deactivateUser: ( state, data ) => {
        return new Promise( done => {
            axios.post( `/user/deactivate`, data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    activateUser: ( state, data ) => {
        return new Promise( done => {
            axios.post( `/user/activate`, data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    resendVerification: ( state, data ) => {
        return new Promise( done => {
            axios.post('/resend/', data, { baseURL: '/api/p' })
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    deleteUser: ( state, data ) => {
        return new Promise( done => {
            axios.post( `/user/delete`, data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    getPossibleSelectedPermissions: (state, data) => {
        return new Promise(done => {
            axios.get(`/possible/permissions/` + data.collection)
                .then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    getPossiblePermissions: (state) =>{
        return new Promise(done => {
            axios.get(`/possible/permission`)
                .then(res => {
                    state.commit('setAllowedPermissions',res.data);
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    getUserSelectedPermissions: (state, data) => {
        return new Promise(done => {
            axios.get(`/permission/for-action/` + data.collection + '?user=' + data.user)
                .then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    getUserPermissions: (state, data) => {
        return new Promise(done => {
            axios.get(`/user/get-permissions/${data.collection}/${data.id}`)
                .then(res => {
                    res.data.data.collection = data.collection;
                    res.data.data.loadedFromServer = true;
                    state.commit('updateCheckboxData',res.data.data);
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    setSingleUserPermission: (state,data) => {
        return new Promise(done => {
            axios.post('/user/set-permission', data)
                .then(res => done(res.data))
                .catch(err => done(err))
        })
    },
    setSingleUserSelectedPermission: (state,data) => {
        return new Promise(done => {
            axios.post('/user/set-selected-permission', data)
                .then(res => done(res.data))
                .catch(err => done(err))
        })
    },
    setSingleSelectedUserPermission: (state,data) => {
        return new Promise(done => {
            axios.post('/user/set-selected-permission', data)
                .then(res => done(res.data))
                .catch(err => done(err))
        })
    },
    manualVerify:(state,data )=>{
        return new Promise(done=>{
            axios.post(`/user/verify`, data ).then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    },
    resetPW:(state, data)=>{
        return new Promise(done =>{
                        axios.post(`/user/reset-password`, data ).then(res => {
                done(res.data)
            }).catch(err => {
                done(err);
            })
        })
    }
}