'use strict'

import axios from "axios";

export default {
    getDashboard(state) {
        return new Promise(done => {
            axios.get(`/admin/dashboard`)
                .then(res => {
                    if (res.data.success) state.commit( 'setDashboardBaseData', res.data.data );

                    done(res.data)
                })
                .catch(err => {
                    done(err);
                })
        })
    }
}