const colors = require("/colors.config.json");
export default {
    moduleList: [],
    singleData: {},
    stateSingleData: {},
    createModuleData: {
        title: '',
        color: `${colors.sienna}`,
        description: '',
        excerpt: '',
        monetizationBundle: '',
    },
    moduleContent: [],
    stateModuleContent: [],
    editorMode: '',
    waitingContentResponse: false,
    filesToSave: [],
    contentUpdateKey: Date.now()
}