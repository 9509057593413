import pss from "@/services/persistent-state-storage"

export default {
    userList: [],
    userIsAuthenticated: pss.get("userIsAuthenticated", {
        isObject: true,
        default: false,
        saveInit: true
    }),
    userBaseUrl: pss.get("userBaseUrl", {
        default: "/",
        saveInit: true
    }),
    userRole: pss.get("userRole", {
        default: "none",
        saveInit: true
    }),
    username: pss.get("username", {
        default: "none",
        saveInit: true
    }),
    userFullName: pss.get("userFullName", {
        default: "none",
        saveInit: true
    }),
    userid: pss.get("userid", {
        default: "none",
        saveInit: true
    }),
    singleUser: {},
    newUserCreated: false
}
