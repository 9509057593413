import axios from "axios";

export default {
  getBookmark: (state, id) => {
    return new Promise((done) => {
      axios
        .get(`/account/bookmark/single/${id}`, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success) state.commit("setBookmark", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getBookmarkList: (state) => {
    return new Promise((done) => {
      axios
        .get(`/account/bookmarks`, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success)
            state.commit("updateBookmarks", res.data.data.bookmarks);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  createBookmark: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/account/bookmark/create`, data, {
          baseURL: "/api/r",
        })
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  updateBookmark: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/account/bookmark/update`, data, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success) state.commit("updateBookmark", data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  deleteBookmark: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/account/bookmark/delete`, data, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success) state.commit("removeBookmark", data.id);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getModule: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/account/module`, data, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success) state.commit("updateFromModule", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  createRemark: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/account/remark/create`, data, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success)
            state.commit("addRemark", {
              _id: res.data.data,
              title: data.title,
            });

          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getMonetizationBundles: (state, data) => {
    return new Promise((done) => {
      axios
        .get(`/account/monetization-bundle/${data.group}`, {
          baseURL: "/api/r",
        })
        .then((res) => {
          state.commit("updateMonetizationBundles", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getContentOfMonetizationBundle: (state, id) => {
    return new Promise((done) => {
      axios
        .get(`/account/monetization-bundle-content/${id}`, {
          baseURL: "/api/r",
        })
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getRemark: (state, id) => {
    return new Promise((done) => {
      axios
        .get(`/account/remark/${id}`, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success) state.commit("setRemark", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  updateRemark: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/account/remark/update`, data, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success) {
            state.commit("updateRemark", data);
          }
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  deleteRemark: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/account/remark/delete`, data, {
          baseURL: "/api/r",
        })
        .then((res) => {
          if (res.data.success) {
            state.commit("removeRemark", data.id);
          }
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getCard: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/account/get-card`, data, {
          baseURL: "/api/r",
        })
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
};
