export default {
    get: state => {
        return {
            isAuthenticated: state.userIsAuthenticated,
            baseUrl: ()=>{
                if(state.userBaseUrl === 'undefined'){
                    return '/'
                } else {
                    return state.userBaseUrl
                }
            },
            role: state.userRole,
            username: state.username
        }
    },
    userList: state => {
        return state.userList;
    },

    singleUser(state) {
        return state.singleUser
    }
}