'use strict'
export default (axios, store)=>{

    let goToLogin;

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        if(goToLogin) clearTimeout(goToLogin);
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
    
    // Add a response interceptor
    axios.interceptors.response.use(function (res) {
        
        if(store.state.user.isAuthenticated || (res.config.url == '/p/login' && res.data.success)){
            goToLogin = setTimeout(()=>{ 
                store.commit('user/redirectUser');
            }, 5*60*1000);
        }
        return res;
    }, function (error) {
        if(error.response.status == 401 || error.response.status == 403) store.commit('user/redirectUser');
        return Promise.reject(error.response.data);
    });
}