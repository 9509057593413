import axios from "axios";

export default {
    create: (state, data) => {
        return new Promise((done) => {
            axios.post("/affiliate-link/create", data)
                .then((res) => done(res.data))
                .catch((err) => done(err));
        });
    },
    getList: ( state , data ) => {
        return new Promise( done => {
            let urlParameter = '';
            if ( data.search || data.titleId || data.excludeId ){
                let urlParameterArray = [];
                if (data.search ) urlParameterArray.push(`search=${data.search}`)
                if (data.excludeId ) urlParameterArray.push(`excludeId=${data.excludeId}`)
                urlParameter = `?${urlParameterArray.join('&')}`
            }
            axios.get( `/affiliate-link/list/${data.page}${urlParameter}` )
                .then(res => {
                    state.commit( 'updateAffiliateList', res.data.data.data )
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getSingle: ( state , data ) => {
        return new Promise( done => {
            axios.get( `/affiliate-link/single/${data.id}` )
                .then(res => {
                    state.commit( 'updateSingleMBData', res.data.data );
                    state.commit( 'updateLocalMBData', res.data.data );
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    update: ( state , data ) => {
        return new Promise( done => {
            axios.post( `/affiliate-link/update`, data )
                .then(res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
}