"use strict";

import axios from "axios";

export default {
  // getters

  /* Gets menu for editor */
  getMenuForEditor: (state, payload) => {
    return new Promise((done) => {
      axios
        .get(`/menu/single/${payload.id}`)
        .then((res) => {
          if (res.data && res.data.success) {
            if (!res.data.data?.body) res.data.data.body = {};
            state.commit("getMenuForEditor", res.data.data);
            // this is needed for settings page to work
            if (
              !payload.fieldsToRefresh &&
              !Array.isArray(payload.fieldsToRefresh)
            ) {
              state.commit("blockEditor/setSingleEntity", res.data.data, {
                root: true,
              });
            } else if (Array.isArray(payload.fieldsToRefresh)) {
              payload.fieldsToRefresh.forEach((field) => {
                let value = res.data.data[field];

                if (res.data.data[field].value) {
                  value = res.data.data[field].value;
                }

                state.commit(
                  "blockEditor/setEntityPropValue",
                  { key: field, value: value },
                  {
                    root: true,
                  }
                );
              });
            }
          }
          done(res.data);
        })
        .catch((err) => {
          done(err);
        });
    });
  },
  // gets paged menu list
  getMenuList: (state, payload) => {
    return new Promise((done) => {
      let urlParameter = "";
      if (payload.searchTerm || payload.titleId || payload.excludeId) {
        let urlParameterArray = [];
        if (payload.searchTerm)
          urlParameterArray.push(`search=${payload.searchTerm}`);
        if (payload.titleId) urlParameterArray.push(`title=${payload.titleId}`);
        if (payload.excludeId)
          urlParameterArray.push(`excludeId=${payload.excludeId}`);
        urlParameter = `?${urlParameterArray.join("&")}`;
      }

      axios
        .get(`/menu/list/${payload.page}${urlParameter}`)
        .then((res) => {
          if (res.data && res.data.success) {
            state.commit("getMenu", res.data.data);
          }
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  // gets all menu objects available on the system
  getAllMenuList: (state) => {
    return new Promise((done) => {
      axios
        .get(`/menu/list-all/`)
        .then((res) => {
          if (res.success && res.data) {
            state.commit("getMenu", res.data);
          }
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  renderMenu: (state, payload) => {
    return new Promise((done) => {
      axios
        .get(`/menu/render/${payload.id}`)
        .then((res) => {
          console.log(res.data);
          if (res.data && res.data.success) {
            done(res.data);
          }
        })
        .catch((err) => {
          done(err);
        });
    });
  },
  // setters
  updateMenu: (state, payload) => {
    return new Promise((done) => {
      axios
        .post(`/menu/edit`, payload)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  createMenu: (state, payload) => {
    return new Promise((done) => {
      axios
        .post(`/menu/create`, payload)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  archive: (state, payload) => {
    return new Promise((done) => {
      axios
        .post(`/menu/archive`, payload)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
};
