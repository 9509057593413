'use strict';

export default {
    getPages: ( state, pages ) => {
        if( pages ){
            state.pages = pages.data;
            state.pagination = pages.pages;
            state.permissions = pages.permissions;
        }
    },
    getPageForEditor: ( state, page ) => {
        if( page ){
            state.singleEntity = page;
        }
    },
    setPages: ( state, pages ) => {
        state.pages = pages;
    }
}