<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    methods:{
         name() {
	        let popup = document.querySelector('.modal-dialog')
            let backdrop = document.querySelector('.modal-backdrop')
            if (!popup && backdrop) {
                backdrop.remove();
                document.querySelector("body").classList.remove("modal-open");
            }
        }
    },
    updated(){
        this.name()
    }
}
</script>

<style lang="scss">
@import "@/styles/main";

#app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    // font-family: 'Oswald', sans-serif;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-image: url('./assets/BACKGRAY-1.jpg');
    background-repeat: repeat;
}
</style>
