"use strict";

export default {
  // not loaded files

  hide_menu: "Menü ausblenden",
  dashboard: "Dashboard",
  modules: "Module",
  overview: "Überblick",
  monetization_bundles: "Bereich",
  user: "Nutzer",
  author_admin: "Autor/Admin",
  accounts: "Accounts",
  contracts: "Verträge",
  messages: "Nachrichten",
  settings: "Einstellungen",
  archive: "Archiv",
  profile: "Profil",
  web: "Web System",
  page: "Seite",
  menu: "Menü",
  media: "Medien",

  seconds: "Sekunden",
  notification_dismiss_after: "Diese Benachrichtigung erlischt nach",
  profile_settings: "Profil",
  login: "Login",
  username: "Benutzername",
  password: "Password",
  forgot_password: "Passwort vergessen?",
  back_to_login: "Zurück zum Login",
  or: "oder",
  register: "Registrieren",
  first_name: "Vorname",
  last_name: "Nachname",
  email: "e-Mail",
  confirm_password: "Passwort bestätigen",
  send: "Senden",
  send_message: "Nachricht senden",
  i_agree_to_the_privacy_policy: (link) =>
    `Ich stimme der <a href="${link}" target='_blank'>Datenschutzerklärung zu</a>`,
  i_agree_with_the_terms_of_use: (link) =>
    `Ich stimme den <a href="${link}" target='_blank'>Nutzungsbedingungen zu</a>`,
  i_would_like_to_subscribe_newsletter: "Sende mir den Newsletter.",
  passHint:
    "Passwortlänge sollte zwischen 8 und 32 Zeichen lang sein. Benutze die Groß- und Kleinschreibung, Zahlen und Symbole für ein starkes Passwort.",
  passCorfirmHint: "Passwörter stimmen nicht überein.",
  emailHint: "Bitte nutze eine gültige e-Mail-Adresse",
  firstNameHint: "Nur Buchstaben erlaubt",
  lastNameHint: "Nur Buchstaben erlaubt",
  required: "Erforderlich",
  forgot_page_description:
    "Trage die mit deinem Account verbundene e-Mail-Adresse ein, damit wir dir einen Link zum Zurücksetzen schicken können.",
  enter_correct_email: "Trage deine richtige e-Mail-Adresse ein",
  your_email: "Deine e-Mail",
  reset_password: "Passwort zurücksetzen",
  hide_menu: "Menü ausblenden",
  logout_error_user:
    "Es gab einen Fehler während des Logout-Prozesses. Wende dich an Marius.Brinkmann@recht-einfach.schule",
  menu: "Menü",
  new_menu: "Neues Menü erstellen",
  no_data: "Keine Daten",
  nothing_found: "Nichts gefunden!",
  title: "Titel",
  tags: "Tags",
  description: "Beschreibung",
  page: "Seite",
  pages: "Seiten",
  new_page: "Neue Seite erstellen",
  save: "Speichern",
  revisions: "Überprüfungen",
  reject: "Ablehnen",
  reinstate: "Wieder einsetzen",
  pending_changes: "Ausstehende Änderungen",
  object_has_been_updated: "Objekt wurde aktualisiert.",
  nothing_to_update_here: "Nichts zum Aktualisieren",
  note: "Notiz",
  menu_title: "Menüptitel",
  menu_item: "Menü-Element",
  last_publication: "Letzte Veröffentlichung",
  last_change: "Letzte Änderung",
  general_settings: "Haupteinstellungen",
  exit_revision_mode: "Überprüfungsmodus verlassen",
  exit_change_mode: "Änderungsmodus verlassen",
  discard_changes: "Änderungen verwerfen",
  changes: "Änderungen",
  are_you_sure_you_want_to_leave_the_page:
    "Bist du sicher, dass du die Seite verlassen willst? Alle ungesicherten Veränderungen gehen verloren.",
  approve: "zustimmen",
  do_you_want_to_leave:
    "Willst du wirklich gehen? Du hast ungesicherte Änderungen!",
  show_more: "Zeige mehr",
  show_less: "Zeige weniger",
  go_back_to_homepage: "Zurück zurück zur Hauptseite",
  not_set: "Nicht gesetzt",
  media: "Medien",
  overview: "Überblick",
  open: "Öffnen",
  permission_error: "Berechtigungsfehler",
  search_error: "Suchfehler",
  db_error:
    "Unbekannter Datenbankfehler. Bitte an den Administrator Marius.Brinkmann@recht-einfach.schule wenden.",
  title_not_found: "Titel nicht gefunden",
  add_media: "Medien hinzufügen",
  add_new_media: "Eine neue Medien-Datei hinzufügen",
  enter_media_name: "Medienname eingeben",
  enter_media_description: "Medienbeschreibung eingeben",
  upload_files: "Datei hochladen",
  media_library: "Medienbibliothek",
  select_media: "Medien auswählen",
  create: "Erstellen",
  close: "Schließen",
  description_required: "Beschreibung ist erforderlich",
  title_required: "Titel ist erforderlich",
  no_permission: "Keine Berechtigung",
  db_statics:
    "Unbekannter Datenbankfehler. Bitte an den Administrator Marius.Brinkmann@recht-einfach.schule wenden.",
  success: "Erfolgreich",
  name_used: "Der Klassifikationsname ist bereits vergeben",
  select_media_format:
    "Wähle apng, avif, gif, jpg, png, svg, webp, mp4, ogv, flv, m4v, webm, asf or avi to upload",
  title_already_used: "Titel wird bereits verwendet",
  name_required: "Name erforderlich",
  text_required: "Text erforderlich",
  parent_required: "Zuordnung erforderlich",
  parent_not_found: "Produkt nicht gefunden",
  no_properties: "No properties",
  no_classification_mounted:
    "Die Klassifikation hat keine Datengruppe oder ist beschädigt. Bitte versuche eine andere oder kontaktiere Marius.Brinkmann@recht-einfach.schule.",
  unexpected_error:
    "Unerwarteter Fehler. Bitte an Marius.Brinkmann@recht-einfach.schule wenden.",
  no_media: "Medien sind erforderlich",
  error_uploading_file: "Fehler beim Hochladen",
  selected_preset: "Gewählte Voreinstellung",
  reset: "Zurücksetzen",
  create_new_entity: (label) => `Erstelle ${label ? label : ""}`,
  enter_entity_name: "Name des Unternehmens eintragen",
  enter_entity_text: "Text des Unternehmens eintragen",
  prefix: "Prefix",
  edit: "Bearbeiten",
  popup_select_key_is_missed: "Der Popup Schlüssel fehlt",
  assignee_is_required: "Empfänger erforderlich",
  change: "Änderungen",
  revision: "Überprüfungen",
  back_to_overview: "Zurück zur Übersicht",
  embed_link: "Einbinden/Link",
  updated: "Aktualisiert",
  created: "Erstellt",
  published: "Veröffentlicht",
  tag_deleted_successfully: "Tag erfolgreich gelöscht",
  payload_required: "Payload ist erforderlich",
  gallery_with_label_should_not_be_empty: (label) =>
    `Galerie mit Bezeichnung "${label}" sollte nicht leer sein`,
  do_you_really_want_to_leave:
    "Möchtest du wirklich gehen? Du hast ungesicherte Änderungen!",
  create_new_report: "Neuen Bericht anlegen",
  reports: "Berichte",
  add: "Hinzufügen",
  by_user: "Vom Nutzer",
  browse_files: "Durchsuchen",
  drag_and_drop_to_upload: (types) => `Drag & Drop: Um ${types}, hochzuladen`,
  allowed_image_types: (types) => `Erlaubte Bild Dateiformate: ${types}`,
  allowed_file_types: (types) => `Erlaubte Dateiformate: ${types}`,
  drag_and_drop_file_to_upload: "Drag & Drop zum Hochladen",
  best_size: (size) => `Beste Größe: ${size ? size : "1024px x 768px"}`,
  upload: "Hochladen",
  media_url: "Medien-URL",
  browser_does_not_support_video:
    "Dein Browser unterstützt das Video-Tag nicht.",
  no_slots_available:
    "Keine freien Plätze auf der Seite. Bitte erwäge das Hinzufügen von Plätzen im Template zu dieser Seite.",
  remove: "Entfernen",
  active_page: "Seite aktiviern",
  template: "Template",
  templates: "Templates",
  are_you_sure_you_want_to_leave_page:
    "Bist du sicher, dass du die Seite verlassen willst? Alle ungesicherten Änderungen gehen verloren.",
  unknown_server_issue: "Unbekanntes Serverproblem",

  // src\views\Account\Bundles\Bundle.vue
  modules_overview: "Modulüberblick",
  // src\views\Account\Bundles\Bundle.vue END

  //src\views\Dashboard\Common\Settings.vue
  unable_to_save_entity_can_not_be_empty: () => "",
  //src\views\Dashboard\Common\Settings.vue END

  //src\views\Dashboard\Contract\Single\Container.vue  ///WORKS///
  save: "Speichern",
  essentiala_negotii: "Vertragsinhalt",
  contractual_parties: "Vertragsparteien",
  contract_tiree: "Vertragsstrafe",
  signatories: "Unterschriften",
  //src\views\Dashboard\Contract\Single\Container.vue END

  //src\views\Dashboard\Contract\Single\ContractContent.vue ///WORKS///
  contract_text: "Vertragstext",
  saved: "Gespeichert",
  last_change: "Letzte Änderung",
  created_by: "Erstellt von",
  last_change_by: "Letzte Änderung von",
  date_of_effect: "Vertragsbegin",
  groups: "Gruppen",
  group: "Gruppe",
  specify_date_of_effect_is_required: "Vertragsbegin ist erforderlich",
  //src\views\Dashboard\Contract\Single\ContractContent.vue END

  //src\views\Dashboard\Contract\Single\ContractSignatories.vue  ///WORKS///
  no_users: "Keine Benutzer",
  //src\views\Dashboard\Contract\Single\ContractSignatories.vue END

  //src\views\Dashboard\Contract\Single\ContractualParties.vue  ///WORKS///
  select: "Wähle",
  admin: "Admin",
  account: "Account",
  deselect: "Entferne Auswahl",
  include: "In Auswahl Einschließen",
  all: "Sämtliche",
  admins: "Admins",
  include_all_admin_users_in_to_selection:
    "Alle Admin-Benutzer in die Auswahl einbeziehen",
  include_admin_users_in_to_selection:
    "Admin-Benutzer in die Auswahl einbeziehen",
  include_account_users_in_to_selection:
    "Kontonutzer in die Auswahl einbeziehen",
  include_all_account_users_in_to_selection:
    "Alle Kontonutzer in die Auswahl einbeziehen",
  accounts: "Accounts",
  no_admins: "Keine Admins",
  //src\views\Dashboard\Contract\Single\ContractualParties.vue END

  //src\views\Dashboard\Contract\Overview.vue  ///WORKS///
  contract_overview: "Vertragsüberblick",
  create_new_contract: "Erstelle neuen Vertrag",
  title: "Titel",
  text: "Text",
  //src\views\Dashboard\Contract\Overview.vue END

  //src\views\Dashboard\DashboardOverview\Main.vue  ///WORKS///
  my_modules: "Meine Module",
  join_module: "Mopdul beitreten",
  notes: "Notizen",
  show_all: "SZeige alle",
  //src\views\Dashboard\DashboardOverview\Main.vue END

  //src\views\Dashboard\Definition\Main.vue   ///WORKS///
  used_in: "Benutzt bei",
  display_title: "Zeige Titel",
  explanation_of_definition: "Erläuterung einer Definition",
  no_data: "Keine Daten",
  definition_title: "Definitionsüberschrift",
  do_you_really_want_to_leave_you_have_unsaved_changes:
    "Wirklich verlassen? Ungesicherte Änderungen!",
  //src\views\Dashboard\Definition\Main.vue END

  //src\views\Dashboard\Media\Single\MediaContent.vue   ///WORKS///
  media_url: "Media url",
  upload: "Hochladen",
  browser_does_not_support_video: "Dein Browser unterstützt kein Video.",
  link_copied: "Link kopiert",
  //src\views\Dashboard\Media\Single\MediaContent.vue END

  //src\views\Dashboard\Media\MediaOverview.vue   ///WORKS///
  add_media: "Medien hinzufügen",
  add_new_media: "Füge neue Medien hinzu",
  enter_media_name: "Gib den Mediennamen ein",
  enter_media_description: "Gib die Medienbeschreibung ein",
  upload_files: "Lade Daten hoch",
  media_library: "Medienbibliothek",
  select_media: "Wähle Medien",
  open: "Öffnen",
  //src\views\Dashboard\Media\MediaOverview.vue END

  //src\views\Dashboard\Menu\Overview\CreateNew.vue   ///WORKS///
  create_new_menu: "Erstelle neues Memü",
  description: "Beschreibung",
  create: "Erstellen",
  close: "Schließen",
  menu_added: "Menü hinzugefügt!",
  //src\views\Dashboard\Menu\Overview\CreateNew.vue END

  //src\views\Dashboard\Menu\Overview\Menu.vue   ///WORKS///
  //src\views\Dashboard\Menu\Overview\Menu.vue END

  //src\views\Dashboard\Menu\Single\GeneralSettings.vue   ///WORKS///
  menu: "Menü",
  path_name: "Menü",
  //src\views\Dashboard\Menu\Single\GeneralSettings.vue END

  //src\views\Dashboard\Menu\Single\Menu.vue   ///WORKS///
  menu_title: "Menütitel",
  general_settings: "Haupteinstellungen",
  object_has_been_updated: "Objekt wurde aktualisiert.",
  menu_item: "Menü-Element",
  //src\views\Dashboard\Menu\Single\Menu.vue END

  //src\views\Dashboard\Messages\Single\Container.vue    ///WORKS///
  recipients: "Empfänger",
  message: "Nachricht",
  message_content: "Nachrichteninhalt",
  //src\views\Dashboard\Messages\Single\Container.vue END

  //src\views\Dashboard\Messages\Single\Notes.vue   ///WORKS///
  //src\views\Dashboard\Messages\Single\Notes.vue END

  //src\views\Dashboard\Messages\Single\Recipients.vue   ///WORKS///
  //src\views\Dashboard\Messages\Single\Recipients.vue END

  //src\views\Dashboard\Messages\Single\Settings.vue   ///WORKS///
  created: "Erstellt",
  hasNotes: "Hat Notizen",
  //src\views\Dashboard\Messages\Single\Settings.vue END

  //src\views\Dashboard\Messages\Overview.vue  ///WORKS///
  messages_overview: "Nachrichtenüberblick",
  create_new_message: "Erstelle neue Nachricht",
  create_new: "Neu erstellen",
  //src\views\Dashboard\Messages\Overview.vue END

  //src\views\Dashboard\Module\CreateSubModule\Main.vue   ///WORKS///
  create_new_module: "Modul erstellen",
  create_new_sub_module: "Untermodul erstellen",
  required: "Erforderlich",
  excerpt: "Auszug",
  free: "Umsonst",
  tags: "Tags",
  authors: "Autoren",
  cancel: "Abbrechen",
  select_content_type: "Wähle Inhaltstyp",
  back_to_basic_input: "Zurück zum Basisinhalt",
  original: "Original",
  link: "Link",
  copy: "Kopie",
  the_current_module_will_have_its_own_original_content:
    "Das derzeitige Modul wird originären Inhalt haben.",
  the_current_module_will_have_a_link_to_the_content_of_another_module:
    "Das derzeitige Modul wird verlinkten (hier unveränderlichen) Inhalt haben.",
  the_current_module_will_have_a_copy_of_the_content_of_another_module:
    "Das derzeitige Modul wird kopierten (änderbaren) Inhalt haben.",
  monetization_bundle: "Bereich",
  create_new_monetization_bundle: "Bereich erstellen",
  detail: "Detail",
  content_used: "Benutzter Inhalt",
  copied: "Kopiert",
  linked: "Verlinkt",
  no_content_selected: "Kein Inhalt ausgewählt",
  //src\views\Dashboard\Module\CreateSubModule\Main.vue END

  // src\views\Dashboard\Module\AddReviewer.vue
  manage_reviewers: "Organisiere Lektoren",
  send: "Senden",
  user_name: "Benutzername",
  add: "Hinzufügen",
  remove_reviewer: "Entfernen",
  reviewer_removed: "Lektor entfernt",
  linked_reviewers: "Verbundene Lektoren",
  available_reviewers: "Verfügbare Lektoren",

  // src\views\Dashboard\Module\AddReviewer.vue END

  // src\views\Dashboard\Module\Analytics.vue

  // (static demo content)

  // src\views\Dashboard\Module\Analytics.vue END

  // src\views\Dashboard\Module\Authors.vue

  write_here_to_search: "Suchen…",
  request_author: "Frage Autor an",
  add_author: "Füge Autor hinzu",
  add_reviewer: "Füge Lektor hinzu",
  unknown: "Unbekannt",

  // src\views\Dashboard\Module\Authors.vue END

  // src\views\Dashboard\Module\CreateModule.vue
  // src\views\Dashboard\Module\CreateModule.vue END

  // src\views\Dashboard\Module\Main.vue

  module_title: "Modulüberschrift",
  editor: "Editor",
  sub_content: "Kapitel",
  analytics: "Analyse",
  discard_changes: "Änderungen verwerfen",
  current_version: "Aktuelle Version",
  media: "Medien",
  success: "Erfolgreich",
  danger: "Gefahr",
  version_of: "Version von",
  approve: "Akzeptieren",
  go_to_the_original: "Zum Original",
  add_note: "Notiz hinzufügen",
  start_review: "Überprüfung beginnen",
  approve_review: "Veröffentlichen",

  // src\views\Dashboard\Module\Main.vue END

  // src\views\Dashboard\Module\Media.vue

  deleted: "Gelöscht",
  upload_audio: "Lade Audiodatei hoch",
  upload_video: "Lade Videodatei hoch",
  select_audio: "Wähle Audiodatei",
  select_video: "Wähle Videodatei",
  video: "Video",

  // src\views\Dashboard\Module\Media.vue END

  // src\views\Dashboard\Module\Overview.vue
  // src\views\Dashboard\Module\Overview.vue END

  // src\views\Dashboard\Module\RequestAuthor.vue

  request_author_to_module: "Autor anfragen",
  add_to_module: "Zum Modul hinzufügen",
  author_has_been_added: "Autor wurde hinzugefügt",

  // src\views\Dashboard\Module\RequestAuthor.vue END

  // src\views\Dashboard\Module\Settings.vue

  color_title: "Farbtitel",
  bookmark: "Lesezeichen",
  bookmarks: "Lesezeichen",
  in_construction: "In Bearbeitung",
  number: "Zahl",
  request_approval: "Einreichen",
  location_content_is_used: "Zielinhalt wird gerade genutzt",
  card_color: "Kartenfarbe",

  // src\views\Dashboard\Module\Settings.vue END

  // src\views\Dashboard\Module\SubContent.vue

  definitions: "Definitionen",
  cards: "Karten",

  // src\views\Dashboard\Module\SubContent.vue END

  // src\views\Dashboard\MonetizationBundle\Settings\Advanced.vue
  // src\views\Dashboard\MonetizationBundle\Settings\Advanced.vue END

  // src\views\Dashboard\MonetizationBundle\Settings\Basic.vue
  // ???
  // src\views\Dashboard\MonetizationBundle\Settings\Basic.vue END

  // src\views\Dashboard\MonetizationBundle\Settings\Pricing.vue
  // ???
  // src\views\Dashboard\MonetizationBundle\Settings\Pricing.vue END

  // src\views\Dashboard\MonetizationBundle\Settings\Settings.vue

  settings: "Einstellungen",
  pricing: "Bezahlung",
  content: "Inhalt",
  banner: "Banner",
  overview: "Überblick",
  checkout: "Checkout",
  archived: "Archiviert",
  status: "Status:",
  coming_soon: "Bald verfügbar",
  beta: "Beta",
  live: "Live",
  complete_analyse_site: "Vollständige Analyse der Website",
  // src\views\Dashboard\MonetizationBundle\Settings\Settings.vue END

  // src\views\Dashboard\MonetizationBundle\Analytics.vue

  // (static demo content)

  // src\views\Dashboard\MonetizationBundle\Analytics.vue END

  // src\views\Dashboard\MonetizationBundle\Main.vue

  basic: "Grundlegend",
  monetization_bundle_title: "Bereichsname",
  content_users_that_responded_to_your_request:
    "Inhaltsnutzer, die auf Ihre Anfrage geantwortet haben",
  no_user_responded_to_your_request:
    "Kein Benutzer hat auf Ihre Anfrage geantwortet",
  // src\views\Dashboard\MonetizationBundle\Main.vue END

  // src\views\Dashboard\MonetizationBundle\Overview.vue
  // ???
  // src\views\Dashboard\MonetizationBundle\Overview.vue END

  // src\views\Dashboard\Page\Overview\CreateNew.vue

  create_new_page: "Erstelle neue Seite",
  page_added: "Seite hinzugefügt",

  // src\views\Dashboard\Page\Overview\CreateNew.vue END

  // src\views\Dashboard\Page\Overview\Page.vue

  new_page: "Neue Seite",
  nothing_found: "Gibbet nich!",
  pages: "Seiten",

  // src\views\Dashboard\Page\Overview\Page.vue END

  // src\views\Dashboard\Page\Single\GeneralSettings.vue

  page: "Seite",

  // src\views\Dashboard\Page\Single\GeneralSettings.vue END

  // src\views\Dashboard\Page\Single\Page.vue

  active_page: "Aktive Seite",
  template: "Template",
  are_you_sure_you_want_to_leave_page:
    "Wirklich verlassen? Ungesicherte Änderungen gehen verloren.",

  // src\views\Dashboard\Page\Single\Page.vue END

  // src\views\Dashboard\Settings\Archive.vue

  archive: "Archiv",
  you_can_select_archive_type:
    "Wähle Archiv-Typ. Dauerhaft oder wiederherstellbar löschen.",
  name: "Name",
  last_action: "Vorherige Aktion",
  actions: "Aktionen",
  restore: "Wiederherstellen",
  delete: "Löschen",
  are_you_sure_you_want_delete: "Wirklich löschen?",
  restored: "Wiederhergestellt",
  module: "Module",
  no_archive_data: "Keine Archivdaten",

  // src\views\Dashboard\Settings\Archive.vue END

  // src\views\Dashboard\Users\CreateUser.vue
  changePassword: "Passwort ändern",
  create_new_user: "Neuen Nutzer erstellen",
  email: "e-Mail",
  initial_password: "Ursprüngliches Passwort",
  confirm_password: "Bestätige Passwort",
  default_language: "Default language",
  create_user: "Erstelle Nutzer",
  user_has_been_registered: "Nutzer wurde registriert",
  country: "Land",
  select_roles_for_user: "Wähle Nutzerrolle (Wähl weise!)",
  author: "Autor",
  reviewer: "Lektor",
  web: "Web",
  system: "System",
  super: "Super",
  creator: "Creator",
  linker: "Linker",

  // src\views\Dashboard\Users\CreateUser.vue END

  // src\views\Dashboard\Users\EditUser.vue

  edit_user: "Bearbeite Nutzer",
  username: "Nutzername",
  first_name: "Vorname",
  middle_name: "Zweiter Vorname",
  last_name: "Nachname",
  state: "Staat",
  street: "Straße",
  zip: "Postleitzahl",
  phones_list: "Telefonliste",
  phone: "Telefonnummer",
  remove: "Entfernen",
  enter_phone_number: "Trage gewünschte Telefonnummer ein...",
  user_updated: "Nutzer wurde aktualisiert",

  // src\views\Dashboard\Users\EditUser.vue END

  // src\views\Dashboard\Users\GeneratePayout.vue

  generate_payout_table: "Erstelle Auszahlungsplan",

  // src\views\Dashboard\Users\GeneratePayout.vue END

  // src\views\Dashboard\Users\Overview.vue

  new: "Neu",
  payout_report: "Auszahlungsplan",
  permissions: "Erlaubnis",
  activate: "Aktivieren",
  deactivate: "Deaktivieren",
  verify: "Verifizieren",

  // src\views\Dashboard\Users\Overview.vue END

  // src\views\Dashboard\ContractsSign.vue

  you_need_sign_contracts: "Du musst den Vertrag bestätigen",
  date: "Datum",
  sign: "Unterschrift",

  // src\views\Dashboard\ContractsSign.vue END

  // src\views\Logon\Login\Register.vue

  // i_agree_with_the_terms_of_use: () => '',
  // i_agree_to_the_privacy_policy: () => '',

  // src\views\Logon\Login\Register.vue END

  // src\views\Logon\Login\Signin.vue
  // src\views\Logon\Login\Signin.vue END

  // src\views\404.vue

  errorCode: "404",
  legal_vacuum: "Seite nicht gefunden",

  // src\views\404.vue END

  // src\views\Client.vue
  // src\views\Client.vue END

  // src\views\ComponentsTest.vue

  // src\views\ComponentsTest.vue END

  // src\views\Dashboard.vue

  role_mode: "Nutzerrollen Modus",
  do_you_really_want_to_leave:
    "Wirklich verlassen? Ungesicherte Änderungen gehen verloren.",

  // src\views\Dashboard.vue END

  // src\views\Dashboard\Module\AddAuthor.vue

  manage_authors: "Bearbeite Autoren",
  remove_author: "Entfernen",
  author_deleted: "Autor entfernt",
  linked_authors: "Autoren verbunden",
  available_authors: "Verfügbare Autoren",
  author_added: "Autor hinzugefügt",

  // src\views\Dashboard\Module\AddAuthor.vue END

  // src\views\Dashboard\Module\Editor\Search.vue
  // src\views\Dashboard\Module\Editor\Search.vue END

  // src\views\Dashboard\Module\Editor\RightSideBar.vue
  // src\views\Dashboard\Module\Editor\RightSideBar.vue END

  // src\views\Dashboard\Module\Editor\Request.vue
  in_review: "im Rückblick",
  review_requested: "Überprüfung angefordert",
  review_status_sub: "Kapitel",
  request_review: "Frage Überprüfung an",
  request_review_text:
    "Mit dem Einreichen deines Inhalts zur Überprüfung wird ein dir zugewiesener Lektor deinen Inhalt überprüfen und ggf. Notizen für dich hinzufügen. Ist alles in Ordnung, wird der Text veröffentlicht, der sodann die aktuelle Version ersetzt. \n\nÄnderungen an (Unter)kapiteln kannst du zur Überprüfung miteinbeziehen.",
  include_sub_module: "Beziehe Unterkapitel mit ein!",

  // src\views\Dashboard\Module\Editor\Request.vue END
  // src/views/Account/Bookmarks/Bookmark.vue
  update: "Aktualisierung",
  // src/views/Account/Bookmarks/Bookmark.vue END
  // src/views/Account/Bookmarks/Create.vue
  create_bookmark: "Erstelle Lesezeichen",
  // src/views/Account/Bookmarks/Create.vue END
  // src/views/Account/Bundles/Modules/Remark/Create.vue
  note: "Notiz",
  create_remark: "Erstelle Notiz",
  remark_added: "Anmerkung erstellt",
  // src/views/Account/Bundles/Modules/Remark/Create.vue END
  // src/views/Account/Bundles/Modules/Remark/Remark.vue
  edit_note_for: "Bearbeite Notiz für",
  // src/views/Account/Bundles/Modules/Remark/Remark.vue END
  // src/views/Account/Bundles/Modules/Module.vue
  remarks: "Anmerkungen",
  last_update: "Zuletzt aktualisiert",
  chapter: "Kapitel",
  part_of: "Teil von:",
  go_right: "nach rechts",
  go_left: "nach links",
  definitions_coach: "Definitionstrainer",
  no_remarks: "Keine Anmerkungen",
  definition_coach: "Definitionstrainer",
  card_training: "Kartentraining",
  last_updated: "Zuletzt aktualisiert",
  author_unknown: "Autor unbekannt",
  // src/views/Account/Bundles/Modules/Module.vue END

  // src/views/Account/Bundles/Bundle.vue
  // src/views/Account/Bundles/Bundle.vue END

  // src/views/Account/Common/NewsletterSubscriptionPopup.vue
  confirm: "Bestätige",
  // src/views/Account/Common/NewsletterSubscriptionPopup.vue END
  // src/views/Account/Common/Notes.vue
  // src/views/Account/Common/Notes.vue END
  // src/views/Account/Common/RemarkPopup.vue
  add_for: "Zufügen zu",
  // src/views/Account/Common/RemarkPopup.vue END
  // src/views/Account/Common/SubscriptionPopup.vue
  click_here_for_details: "Details",
  // src/views/Account/Common/SubscriptionPopup.vue END
  // src/views/Account/Definitions/Definition.vue
  definitions_text: "Definitionstext",
  reveal: "Aufdecken",
  next: "Nächste",
  // src/views/Account/Definitions/Definition.vue END
  // src/views/Account/Modules/Module/_helperSubPackage.vue
  themes: "Themes",
  back_to: "Back to: GS 4.1: Introduction to Criminal Law Dogmatics",
  next_chapter: "Nächstes Kapitel",
  // src/views/Account/Modules/Module/_helperSubPackage.vue END
  // src/views/Account/Modules/Main.vue
  preparing_for_the_section_process: "Preparing for the section process",
  note_title: "Notizüberschrift",
  note_content: "Notizinhalt",
  note_title_placeholder: "Notiztitel",
  note_content_placeholder: "Notizinhalt",
  // src/views/Account/Modules/Main.vue END
  // src/views/Account/Modules/ModuleOverview.vue
  login_register: "Login/Registrieren",
  module_overview: "Modulüberblick",
  // src/views/Account/Modules/ModuleOverview.vue END
  // src/views/Account/Bookmarks.vue
  please_authorize_to_watch_the_content:
    "Bitte authorisiere dich, um den Inhalt ansehen zu können.",
  no_bookmarks_added: "Keine Lesezeichen hinzugefügt",
  // src/views/Account/Bookmarks.vue END
  // src/views/Account/Bundles.vue
  bundles: "Bereiche",
  // src/views/Account/Bundles.vue END
  // src/views/Account/Profile.vue
  user_account: "Nutzerprofil",
  download_user_data: "Download Nutzerprofil",
  to_update: "zum Aktualisieren",
  deleting_an_account: "Lösche Nutzerprofil",
  change_image: "Ändere das Bild",
  male: "Männlich",
  female: "Weiblich",
  various: "Divers",
  birthday: "Geburtstag",
  profile_picture: "Profilbild",
  security: "Sicherheit",
  gender: "Geschlecht",
  email_address: "e-Mailaddresse",
  old_password: "Altes Passwort",
  new_password: "Neues Passwort",
  repeat_the_new_password: "Neues Passwort wiederholen",
  address: "Adresse",
  street_name_house_number: "Straßenname / Hausnummer",
  city: "Stadt",
  postal_code: "Postleitzahl",
  // src/views/Account/Profile.vue END
  // src/views/Dashboard/Affiliate/Overview.vue
  create_new_affiliate_link: "Erstelle neuen Affiliate-link",
  affiliate_links_overview: "Affiliate-Link Überblick",
  // src/views/Dashboard/Affiliate/Overview.vue END
  // src/views/Dashboard/Card/Main.vue
  question: "Frage",
  answer: "Antwort",
  card_title: "Kartentitel",
  related_package: "Zugehöriges Paket",
  // src/views/Dashboard/Card/Main.vue END
  // src/views/Dashboard/Common/BlocksEditor.vue
  available_slots: " Verfügbare Plätze :",
  // src/views/Dashboard/Common/BlocksEditor.vue END
  // src/views/Dashboard/Common/Container.vue
  alt_text: "Alternativer Text",
  copy_link: "Kopierter Link",
  // src/views/Dashboard/Common/Container.vue END
  // src/views/Dashboard/Common/CreateEntity.vue
  field: "Feld",
  // src/views/Dashboard/Common/CreateEntity.vue END
  // src/views/Dashboard/Common/PopupSelect.vue
  search: "Suche",
  not_set: "Nicht gesetzt",
  // src/views/Dashboard/Common/PopupSelect.vue END
  // src/views/Dashboard/Module/Editor/Editor.vue
  revert: "Zurück",
  new_sub_module: "Neues Kapitel",
  definition: "Definition",
  create_new_card: "Erstelle eine neue Karte",
  create_new_definition: "Erstelle eine neue Definition",
  paste_definition: "Füge Definition hinzu",
  select_definition: "Wähle Definition",
  copying_to_clipboard_successful:
    "Kopiervorgang zum Clipboard war erfolgreich",
  color_field: "Farbfeld",
  image: "Bild",
  upload_image: "Lade Bild hoch",
  select_image: "Wähle Bild",
  cancel_review: "Überprüfung abbrechen",
  review_in_progress: "Wird überprüft",
  // src/views/Dashboard/Module/Editor/Editor.vue END
  // src/views/Dashboard/Module/Editor/History.vue
  history: "Verlauf",
  display_selected_version: "Zeige ausgewählte Version",
  // src/views/Dashboard/Module/Editor/History.vue END

  //Months

  january: "Januar",
  february: "Februar",
  march: "März",
  april: "April",
  may: "Mai",
  june: "Juni",
  july: "Juli",
  august: "August",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "Dezember",

  // Footer

  part_of_system: "Diese Seite ist Teil der recht-einfach.schule Webseite",
  t_and_c: "AGB",
  privacy: "Privacy",
  inprint: "Inprint",

  // Common
  home: "Startseite",
  no_comments_so_far: "Bislang keine Kommentare",
  affiliate_link: "Partner-Link",
  card: "Karte",
  contract: "Vertrag",
  enter_the_name: "Geben Sie den Namen ein",
  incorrect_name: "Falscher Name",
  no_messages: "Keine Nachrichten",
  add_to_menu: "Zum Menü hinzufügen",
  additional_optional_information: "Zusätzliche fakultative Informationen",
  unpublished_message: "Unveröffentlichte Nachricht",
  published_message: "Veröffentlichte Nachricht",
  added_note: "Hinweis hinzugefügt",
  deleted_note: "Notiz gelöscht",
  updated_note: "Aktualisierter Hinweis",

  invitation_to_join: "Einladung zum Beitritt",
  as_an_author: "als Autor",
  signed: "Unterzeichnet at",
  preview: "Vorschau",
  select_subscription: "Abonnement auswählen",
  per_month: "Pro Monat",
  deactivate : "Deaktivieren",
  activate : "Aktivieren",
  generate: "Erzeugen",
  add_content_bullet_point: "Inhaltlichen Aufzählungspunkt hinzufügen",
  max_word_count: "Maximale Wortanzahl",
  generate_content: "Inhalt generieren",
  tokens: "wertmarke(n)"
};
