export default [{
    path: '/login',
    component: () => import ('@/views/Logon/Login.vue'),
    children: [{
        path: '/',
        name: 'login',
        components: {
            login: () =>
                import ('@/views/Logon/Login/Signin.vue')
        }
    }, {
        path: '/register',
        name: 'register',
        components: {
            register: () =>
                import ('@/views/Logon/Login/Register.vue')
        },
    }, ]
}, {
    path: '/lost-password',
    name: 'lost-password',
    component:  () => import ('@/views/Logon/Forgot')
    
}]