import Vue from 'vue'

import jQuery from "jquery";
Vue.prototype.$ = jQuery;
Vue.prototype.jQuery = jQuery;

import prepareForm from '@/services/prepare-formdata';
Vue.prototype.$prepareForm = prepareForm;

Vue.prototype.$capitalize = ( value ) => value.charAt(0).toUpperCase() + value.slice(1);

Vue.prototype.$isObject = ( value ) => {
  if ( value && value !== null && typeof value === 'object' && !Array.isArray(value) ) return true;
  return false;
};

import countChanges from "@/services/count-changes";
Vue.prototype.$countChanges = countChanges;

import { sortUp } from '@/services/sorting';
import { sortDown } from '@/services/sorting';
Vue.prototype.$sortUp = sortUp;
Vue.prototype.$sortDown = sortDown;