import axios from "axios";

export default {
    create: (state, data) => {
        if (!data.add_to_menu !== false) data.add_to_menu = false;

        if (typeof data.add_to_menu == 'string') data.add_to_menu = data.add_to_menu === 'true' ? true : false;

        return new Promise((done) => {
            axios.post("/monetization-bundle/create", data)
                .then((res) => done(res.data))
                .catch((err) => done(err));
        });
    },
    getMonetizationBundleList: ( state , data ) => {
        return new Promise( done => {
            let urlParameter = '';
            if ( data && (data.search || data.titleId || data.excludeId )){
                let urlParameterArray = [];
                if (data.search ) urlParameterArray.push(`search=${data.search}`)
                if (data.excludeId ) urlParameterArray.push(`excludeId=${data.excludeId}`)
                urlParameter = `?${urlParameterArray.join('&')}`
            }
            axios.get( `/monetization-bundle/list${urlParameter}` )
                .then(res => {
                    state.commit( 'updateMonetizationBundleList', res.data.data.data )
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getSingle: ( state , data ) => {
        return new Promise( done => {
            axios.get( `/monetization-bundle/single/${data.id}` )
                .then(res => {
                    state.commit( 'updateSingleMBData', { ...res.data.data, requestDone: true } );
                    state.commit( 'updateLocalMBData', { ...res.data.data, requestDone: true } );
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    update: ( state , data ) => {
        return new Promise( done => {
            axios.post( `/monetization-bundle/update`, data )
                .then(res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    archive: (context, data) => {
        return new Promise((done) => {
            axios
            .post(`/monetization-bundle/archive`, data)
            .then((res) => {
                done(res.data);
            })
            .catch((err) => done(err));
        });
    }
}