export default [
    {
        path: "/dashboard/contracts",
        name: "contracts",
        meta: {
            relation: "contracts"
        },
        components: {
            dashboard: () => import("@/views/Dashboard/Contract/Overview.vue")
        },
        children: [
            {
                path: "/dashboard/contracts/create",
                name: "create-contract",
                meta: {
                    relation: "contracts",
                },
                components: {
                    create_contract: () => import("@/views/Dashboard/Common/CreateEntity.vue"),
                },
            },
        ],
    },
    {
        path: "/dashboard/contract/:id",
        name: "contract-container",
        meta: {
            relation: "contracts"
        },
        components: {
            dashboard: () => import("@/views/Dashboard/Contract/Single/Container.vue")
        },
        children: [
            {
                path: "/dashboard/contract/:id/content",
                name: "contract-content",
                meta: {
                    relation: "contracts"
                },
                components: {
                    contract_container: () => import("@/views/Dashboard/Contract/Single/ContractContent.vue")
                }
            },
            {
                path: "/dashboard/contract/:id/contractual-parties",
                name: "contractual-parties",
                meta: {
                    relation: "contracts"
                },
                components: {
                    contract_container: () => import("@/views/Dashboard/Contract/Single/ContractualParties.vue")
                }
            },
            {
                path: "/dashboard/contract/:id/signatories",
                name: "signatories",
                meta: {
                    relation: "contracts"
                },
                components: {
                    contract_container: () => import("@/views/Dashboard/Contract/Single/ContractSignatories.vue")
                }
            },
        ]
    }
]
