import pss from '@/services/persistent-state-storage'

export default {
    redirectUser() {
        pss.clear()
        this.commit('setUserIsAuthenticated', pss.get('userIsAuthenticated', {
            isObject: true,
            default: false,
            saveInit: true
        }))
        this.commit('setUserBaseUrl', pss.get('userBaseUrl', {
            default: '/',
            saveInit: true
        }))
        this.commit('setUserRole', pss.get('userRole', {
            default: 'none',
            saveInit: true
        }))
        this.commit('setUsername', pss.get('username', {
            default: 'none',
            saveInit: true
        }))
        this.commit('setUserFullName', pss.get('userFullName', {
            default: 'none',
            saveInit: true
        }))

        location.reload();
    },
    setUserIsAuthenticated: (state, data) => state.userIsAuthenticated = data,
    setUserBaseUrl: (state, data) => state.userBaseUrl = data,
    setUserRole: (state, data) => state.userRole = data,
    setUsername: (state, data) => state.username = data,
    setUserFullName: (state, data) => state.userFullName = data,
    setUserid: (state, data) => state.userid = data,
    setAllUsersData: (state , data) => {
        state.userList = data.data.data
    },
    addUserData: (state, data) => {
        if (data && typeof data === 'object') state.userList.push(data)
    },
    setSingleUserData: (state, data) => state.singleUser = data,
    setUserStoreStateByKey: (state, data) => state[data.key] = data.value
}