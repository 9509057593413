'use strict';

export default [
	{
		path: '/dashboard/menu',
		name: 'overview-menu',
		meta: {
			relation: "menu"
		},
		components: {
			dashboard: () => import(
				'@/views/Dashboard/Menu/Overview/Menu'
			)
		},
		children: [
			{
				path: '/dashboard/menu/create-new',
				name: 'create_new_menu',
				components: {
					create_new_menu: () => import(
						'@/views/Dashboard/Menu/Overview/CreateNew'
					)
				}
			}
		]
	},
	{
		path: '/dashboard/menu/:id',
		name: 'single_menu',
		meta: {
			relation: "menu"
		},
		components: {
			dashboard: () => import(
				'@/views/Dashboard/Menu/Single/Menu'
			)
		},
	},
	{
		path: '/dashboard/menu/:id/settings',
		name: 'single_menu_settings',
		meta: {
			relation: "menu"
		},
		components: {
			dashboard: () => import(
				'@/views/Dashboard/Menu/Single/GeneralSettings'
			)
		},
	}
];