export default {
    updateAffiliateList: ( state, data ) => state.affiliateList = data,

    updateSingleMBData: ( state, payload ) => state.singleData = payload,

    updateLocalMBData: ( state, payload ) => {
        let copy = JSON.parse(JSON.stringify(payload))
        for ( let prop in payload ) {
            if ( ['title', 'description'].includes(prop) ) {
                state.localMBData[prop] = copy[prop];
            }
        }
    },

    updateLocalMBDataString: ( state, payload ) => state.localMBData[payload.key] = payload.value,
}