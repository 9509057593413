export default {
	statusLoadingSkeleton: false,
	// mediaPermissions: '',
	overviewPermissions: {},
	singleData: {},
	stateSingleData: {},
	mediaList: [],
	// mediaValues: {},
	xFormStatus: '',
	showXActions: false,
	changedSettings: [],
	changedMain: [],
	createMediaData: {
        title: ''
    },
	localMediaData: {}
}