// clones object assigned to state to prevent this issue: 
// Direct assignment like this ( let a = b ) just assigns the address to the current variable, and the value pointed to by the address is still the same object. Therefore, modifying the parameter data in other places is equivalent to modifying the selectedSpec in the state, and then an error will be reported. The same is true for arrays.
export default function deepClone(source) {
	if (!source || typeof source !== 'object') {
	  throw new Error('error arguments', 'shallowClone');
	}

	if( source instanceof File ) return source;
	
	var targetObj = source.constructor === Array ? [] : {};
	for (var keys in source) {
	  if (source.hasOwnProperty(keys)) {
		if (source[keys] && typeof source[keys] === 'object' ) {
			if( source[keys] instanceof File ){
				targetObj[keys] = source[keys];
			}else{
				targetObj[keys] = source[keys].constructor === Array ? [] : {};
		  		targetObj[keys] = deepClone(source[keys]);
			}
		} else {
		  targetObj[keys] = source[keys];
		}
	  }
	}
	return targetObj;
}