import axios from "axios";

export default {
    create: (state, data) => {
        return new Promise((done) => {
            axios.post("/contract/create", data)
                .then( res => done(res.data))
                .catch( err => done(err));
        });
    },
    delete: (state, data) => {
        return new Promise((done) => {
            axios.post("/contract/delete", data)
                .then( res => done(res.data))
                .catch( err => done(err));
        });
    },
    archive: (state, data) => {
        return new Promise((done) => {
            axios.post("/contract/archive", data)
                .then( res => done(res.data))
                .catch( err => done(err));
        });
    },
    signContract: (state, data) => {
        let baseUrl = '/api/ra';

        if (data.baseUrl) baseUrl = data.baseUrl;

        return new Promise((done) => {
            axios.get(`/contract/sign/${data.id}`, {
                baseURL: baseUrl
            })
                .then( res => done(res.data))
                .catch( err => done(err));
        });
    },
    getList: (state, data) => {
        return new Promise((done) => {
            let urlParameter = "";
            if (data.search || data.titleId || data.excludeId || data.parent) {
                let urlParameterArray = [];
                if (data.search) urlParameterArray.push(`search=${data.search}`);
                if (data.excludeId) urlParameterArray.push(`excludeId=${data.excludeId}`);
                if (data.parent) urlParameterArray.push(`parent=${data.parent}`);
                urlParameter = `?${urlParameterArray.join("&")}`;
            }
            axios.get(`/contract/list/${data.page}${urlParameter}`)
                .then((res) => {
                    state.commit("updateContractsList", res.data.data.data);
                    done(res.data);
                })
                .catch((err) => done(err));
        });
    },
    getSingle: ( state, data ) => {
        return new Promise((done) => {
            axios.get(`/contract/single/${data.id}`)
                .then( res => {
                    state.commit("updateSingleContract", res.data.data);
                    state.commit("updateCreateContractData", res.data.data);
                    done(res.data)
                })
                .catch( err => done(err));
        });
    },
    update: ( state, data ) => {
        return new Promise( done => {
            axios.post( `/contract/update`, data )
            .then( res => {
                if (res.data.success) state.commit('updateSingleContract', res.data.data);
                done( res.data )
            } )
            .catch( err => done( err ) )
        })
    },

    toggleActive: ( state, data ) => {
        return new Promise( done => {
            axios.post( `/contract/toggle-active`, data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        })
    },
};