export default {
  module: {},
  authors: [],
  monetizationBundle: {},
  monetizationBundles: [],
  remarks: [],
  remark: {},
  bookmarks: [],
  bookmark: {},
  medias: [],
  cards: [],
  card: {
    related_root: null,
    content_0: "",
    content_1: "",
    used: [],
  }
};
