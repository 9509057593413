/**
 * /account/bundle/:monetization_bundle_id/module/:module_id
 *
 * /account/bundle/:monetization_bundle_id/module/:module_id/bookmark/create
 *
 * /account/bundle/:monetization_bundle_id/module/:module_id/remark/create
 * /account/bundle/:monetization_bundle_id/module/:module_id/remark/:remark_id
 */

export default [
  {
    path: 'bundle/:monetization_bundle_id/module/:module_id',
    name: 'bundle-module',
    components: {
      client: () => import('@/views/Account/Bundles/Modules/Module.vue')
    },
    children: [
      {
        path: 'bookmark/create',
        name: 'bundle-module-bookmark-create',
        meta: {
          relation: 'bundle-module',
        },
        components: {
          module: () => import('@/views/Account/Bookmarks/Create.vue')
        },
      },
      {
        path: 'remark/create',
        name: 'bundle-module-remark-create',
        meta: {
          relation: 'bundle-module',
        },
        components: {
          module: () => import('@/views/Account/Bundles/Modules/Remark/Create.vue')
        },
      },
      {
        path: 'remark/:remark_id',
        name: 'bundle-module-remark-single',
        meta: {
          relation: 'bundle-module',
        },
        components: {
          module: () => import('@/views/Account/Bundles/Modules/Remark/Remark.vue')
        },
      },
    ]
  },
  {
    path: 'bundle/:monetization_bundle_id/module-preview/:module_id',
    name: 'bundle-module-preview',
    meta: {
      relation: "bundle-module",
    },
    components: {
      client: () => import("@/views/Account/Bundles/Modules/Module.vue"),
    },
  },
  {
    path: "module/:id",
    name: "account-module",
    meta: {
      relation: "modules",
    },
    components: {
      client: () => import("@/views/Account/Bundles/Modules/Module.vue"),
    },
  },
  {
    path: "module-review/:id",
    name: "module-review",
    meta: {
      relation: "modules",
    },
    components: {
      client: () => import("@/views/Account/Bundles/Modules/Module.vue"),
    },
  },
  {
    path: "bundle/:monetization_bundle_id/module/:module_id/definitions",
    name: "module-definitions",
    meta: {
      relation: "definitions",
    },
    components: {
      client: () => import("@/views/Account/Definitions/Definition.vue"),
    },
  },
  {
    path: "bundle/:monetization_bundle_id/module/:module_id/cards",
    name: "module-cards",
    meta: {
      relation: "definitions",
    },
    components: {
      client: () => import("@/views/Account/Definitions/Definition.vue"),
    },
  },
];
