import countChanges from "@/services/count-changes";

export default {
    monetizationBundleList: state => {
        return state.monetizationBundleList.filter( item => {
            switch ( item.status ) {
                case 0:
                    return item.status = 'coming soon';
                case 1:
                    return item.status = 'beta';
                case 2:
                    return item.status = 'live';
            }
        } );
    },
    countChanges: state => {
        return countChanges( state.localMBData, state.singleData);
        // let counter = 0;
        // let changedKeys = [];
        // if ( Object.keys(state.singleData).length ) {
        //     for ( let prop in state.localMBData ) {
        //         if ( Object.hasOwnProperty.call( state.localMBData, prop ) ) {
        //             if ( prop === 'image' && state.singleData[prop] !== state.localMBData[prop] ) {
        //                 counter += 1;
        //                 changedKeys.push(prop);
        //             } else if (
        //                 ['string','number','boolean'].includes(typeof state.localMBData[prop]) &&
        //                 state.singleData[prop] !== state.localMBData[prop]
        //             ) {
        //                 counter += 1;
        //                 changedKeys.push(prop);
        //             }
        //             else if ( Array.isArray( state.localMBData[prop] ) ) {
        //                 const arr1 = state.localMBData[prop];
        //                 const arr2 = state.singleData[prop];
        //                 const equal = arr1.length == arr2.length && arr1.every((v,i)=>v === arr2[i])
        //                 if ( !equal ) {
        //                     counter += 1;
        //                     changedKeys.push(prop);
        //                 }
        //             }
        //             else if ( typeof state.localMBData[prop] === 'object' && state.singleData[prop] && state.localMBData[prop] ) {
        //                 for ( let key in state.localMBData[prop] ) {
        //                     if ( Object.hasOwnProperty.call( state.localMBData[prop], key ) ) {
        //                         if ( key === 'image' && state.singleData[prop][key] !== state.localMBData[prop][key] ) {
        //                             counter += 1;
        //                             changedKeys.push(prop);
        //                         } else if (
        //                             ['string','number','boolean'].includes(typeof state.localMBData[prop][key]) &&
        //                             state.singleData[prop][key] !== state.localMBData[prop][key]
        //                         ) {
        //                             counter += 1;
        //                             changedKeys.push(prop);
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        // let output = {
        //     saveBtnDisabled: true,
        //     changedKeys
        // }
        // if ( counter ) output.saveBtnDisabled = false;
        // return output;
    },
}