export default {
    updateModulesList: (state, payload) => state.moduleList = payload,
    
    updateSingleModule: (state, payload) => {
        state.stateSingleData = payload;
        state.singleData = payload;

        // let singleDataCopy = JSON.parse(JSON.stringify(payload));
        // let keys = ['title', 'description', 'excerpt', 'color', 'free', 'notes'];
        // keys.forEach( key => {
        //     if ( singleDataCopy.hasOwnProperty( key ) ) {
        //         state.singleData[key] = singleDataCopy[key];
        //     }
        // } );
    },

    updateCreateModuleData: (state, payload) => {
        state.createModuleData = payload;
    },

    updateModuleContent: (state, payload) => {
        let content = []
        payload.forEach( item => {
            let obj = item;
            if ( obj.data === undefined ) {
                obj.data = '';
            }
            content.push( obj );
        } );
        state.moduleContent = JSON.parse(JSON.stringify( content ));
        state.stateModuleContent = JSON.parse(JSON.stringify( content ));
    },

    updateContentBlock: ( state, payload ) => {
        let moduleContentCopy = JSON.parse(JSON.stringify(state.moduleContent));
        moduleContentCopy[payload.index].data = payload.data;
        state.moduleContent = moduleContentCopy;
    },

    addSingleDataNotes: (state, payload) => {
        state.singleData.notes.push(payload);
    },

    setSingleDataNotes: (state, payload) => {
        state.singleData.notes = payload;
    },

    setContent: (state, payload) => {
        state.singleData.content = payload;
        state.stateSingleData.content = payload;
    },

    addContentBlock: ( state, payload ) => state.moduleContent.push( payload ),

    removeContentBlock: ( state, index ) => state.moduleContent.splice( index, 1 ),

    resetContentBlock: ( state ) => {
        state.moduleContent = [];
        state.stateModuleContent = [];
    },

    setSingleDataMedia: (state, payload) => state.stateSingleData.media = payload,

    updateSuggestedAuthors: (state, payload) => state.stateSingleData.suggestedAuthors = payload,

    setEditorMode: (state, payload) => state.editorMode = payload,

    setContentResponseWaiting: (state, payload) => state.waitingContentResponse = payload,

    setSingleDataReviewStatus: (state, payload) => state.stateSingleData.review.status = payload,

    updateStateSingleData: (state, payload) => {
        state.stateSingleData = payload
    },

    pushLocalMedia: (state, payload) => {
        state.filesToSave.push(payload);
    },

    resetLocalMedia: (state ) => {
        state.filesToSave = [];
    },

    spliceLocalMedia: (state, indexOfFile) => {
        state.filesToSave.splice(indexOfFile, 1);
    },

    refreshContentUpdateKey: (state) => state.contentUpdateKey = Date.now()
};