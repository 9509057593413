'use strict';

export default [
	{
		path: '/dashboard/pages',
		name: 'overview-page',
		meta: {
			relation: "page"
		},
		components: {
			dashboard: () => import(
				'@/views/Dashboard/Page/Overview/Page'
			)
		},
		children: [
			{
				path: '/dashboard/pages/create-new',
				name: 'create_new_page',
				components: {
					create_new_page: () => import(
						'@/views/Dashboard/Page/Overview/CreateNew'
					)
				}
			}
		]
	},
	
	{
		path: '/dashboard/pages/:id',
		name: 'single_page',
		meta: {
			relation: "page"
		},
		components: {
			dashboard: () => import(
				'@/views/Dashboard/Page/Single/Page'
			)
		},
	},
	{
		path: '/dashboard/pages/:id/settings',
		name: 'single_page_settings',
		meta: {
			relation: "page"
		},
		components: {
			dashboard: () => import(
				'@/views/Dashboard/Page/Single/GeneralSettings'
			)
		},
	}
];